import React from 'react'

import { useBrand } from '../context/Brand'


function Logo() {
  const { logo } = useBrand()

  if (logo)
    return <div className='logo img'>
      <img src={logo} />
    </div>

  return <div className='logo svg'>
    <svg viewBox='0 0 611.2 143.1'>
      <g>
        <path fill='#1D75B3' d='M222.4,18.1l30.7,91L284,17.4c1.6-4.8,2.8-8.2,3.6-10c0.8-1.9,2.1-3.6,4-5.1c1.9-1.5,4.4-2.3,7.6-2.3
          c2.3,0,4.5,0.6,6.5,1.8c2,1.2,3.6,2.7,4.7,4.7c1.1,1.9,1.7,3.9,1.7,5.9c0,1.4-0.2,2.8-0.6,4.4c-0.4,1.6-0.8,3.1-1.4,4.6
          c-0.6,1.5-1.1,3.1-1.7,4.7l-32.9,88.7c-1.2,3.4-2.3,6.6-3.5,9.7c-1.2,3.1-2.5,5.7-4.1,8.1c-1.5,2.3-3.6,4.2-6.2,5.7
          c-2.6,1.5-5.7,2.2-9.4,2.2c-3.7,0-6.8-0.7-9.4-2.2c-2.6-1.4-4.6-3.4-6.2-5.7c-1.6-2.4-2.9-5.1-4.1-8.1c-1.2-3-2.3-6.2-3.5-9.6
          l-32.3-87.9c-0.6-1.6-1.1-3.2-1.7-4.7c-0.6-1.5-1.1-3.2-1.5-5c-0.4-1.8-0.6-3.3-0.6-4.5c0-3.1,1.3-6,3.8-8.6
          c2.5-2.6,5.7-3.9,9.5-3.9c4.7,0,8,1.4,10,4.3C218.4,7.2,220.4,11.8,222.4,18.1z'/>
        <path fill='#1D75B3' d='M333.9,123.6V16.7c0-5.6,1.3-9.7,3.8-12.5c2.5-2.8,5.8-4.2,9.8-4.2c4.1,0,7.5,1.4,10,4.1
          c2.6,2.7,3.8,6.9,3.8,12.5v106.9c0,5.6-1.3,9.8-3.8,12.6c-2.6,2.8-5.9,4.2-10,4.2c-4,0-7.2-1.4-9.8-4.2
          C335.1,133.3,333.9,129.1,333.9,123.6z'/>
        <path fill='#1D75B3' d='M393.2,123.6V16.7c0-5.6,1.3-9.7,3.8-12.5c2.5-2.8,5.8-4.2,9.8-4.2c4.1,0,7.5,1.4,10,4.1
          c2.6,2.7,3.8,6.9,3.8,12.5v106.9c0,5.6-1.3,9.8-3.8,12.6c-2.6,2.8-5.9,4.2-10,4.2c-4,0-7.2-1.4-9.8-4.2
          C394.5,133.3,393.2,129.1,393.2,123.6z'/>
        <path fill='#1D75B3' d='M471.5,18.1l30.7,91l30.8-91.6c1.6-4.8,2.8-8.2,3.6-10c0.8-1.9,2.1-3.6,4-5.1c1.9-1.5,4.4-2.3,7.6-2.3
          c2.3,0,4.5,0.6,6.5,1.8c2,1.2,3.6,2.7,4.7,4.7c1.1,1.9,1.7,3.9,1.7,5.9c0,1.4-0.2,2.8-0.6,4.4c-0.4,1.6-0.8,3.1-1.4,4.6
          c-0.6,1.5-1.1,3.1-1.7,4.7l-32.9,88.7c-1.2,3.4-2.3,6.6-3.5,9.7c-1.2,3.1-2.5,5.7-4.1,8.1c-1.5,2.3-3.6,4.2-6.2,5.7
          c-2.6,1.5-5.7,2.2-9.4,2.2c-3.7,0-6.8-0.7-9.4-2.2c-2.6-1.4-4.6-3.4-6.2-5.7c-1.6-2.4-2.9-5.1-4.1-8.1c-1.2-3-2.3-6.2-3.5-9.6
          l-32.3-87.9c-0.6-1.6-1.1-3.2-1.7-4.7c-0.6-1.5-1.1-3.2-1.5-5c-0.4-1.8-0.6-3.3-0.6-4.5c0-3.1,1.3-6,3.8-8.6
          c2.5-2.6,5.7-3.9,9.5-3.9c4.7,0,8,1.4,10,4.3C467.5,7.2,469.5,11.8,471.5,18.1z'/>
        <circle fill='#1D75B3' cx='596.4' cy='128.3' r='14.7'/>
        <path fill='#1D75B3' d='M596.4,0L596.4,0c-7.6,0-13.7,6.2-13.7,13.7v76.1c0,7.6,6.2,13.7,13.7,13.7l0,0c7.6,0,13.7-6.2,13.7-13.7
          V13.7C610.2,6.2,604,0,596.4,0z'/>
      </g>
      <g>
        <path fill='#1D75B3' d='M142.6,21.1l-10.4,9.5c-1.3,1.2-3.4,0.3-3.4-1.5v-6.7c0-12.3-10.1-22.4-22.4-22.4h-84C10.1,0,0,10.1,0,22.4
          v83.3c0,0,0,0,0,0v28.3c0,6.8,7.4,11.1,13.3,7.7l26.6-15.3c0.3-0.2,0.7-0.3,1-0.3h65.5c12.3,0,22.4-10.1,22.4-22.4V97
          c0-1.8,2.1-2.7,3.4-1.5l10.4,9.5c6.6,6.1,17.3,1.4,17.3-7.6V28.7C159.9,19.7,149.2,15.1,142.6,21.1z'/>
        <g>
          <path fill='#FFFFFF' d='M28.9,30.4l18.8,55.7L66.6,30c1-2.9,1.7-5,2.2-6.1c0.5-1.2,1.3-2.2,2.4-3.1c1.1-0.9,2.7-1.4,4.6-1.4
            c1.4,0,2.8,0.4,4,1.1c1.2,0.7,2.2,1.7,2.9,2.9c0.7,1.2,1,2.4,1,3.6c0,0.8-0.1,1.7-0.3,2.7c-0.2,1-0.5,1.9-0.9,2.8
            c-0.3,0.9-0.7,1.9-1,2.9L61.5,89.5c-0.7,2.1-1.4,4.1-2.2,5.9c-0.7,1.9-1.5,3.5-2.5,4.9c-0.9,1.4-2.2,2.6-3.8,3.5
            c-1.6,0.9-3.5,1.4-5.8,1.4c-2.3,0-4.2-0.4-5.8-1.3c-1.6-0.9-2.8-2.1-3.8-3.5c-1-1.5-1.8-3.1-2.5-5c-0.7-1.9-1.4-3.8-2.2-5.9
            L13.4,35.7c-0.3-1-0.7-1.9-1-2.9c-0.4-0.9-0.7-2-0.9-3.1c-0.2-1.1-0.4-2-0.4-2.8c0-1.9,0.8-3.7,2.3-5.3c1.5-1.6,3.5-2.4,5.8-2.4
            c2.9,0,4.9,0.9,6.1,2.6C26.5,23.7,27.7,26.5,28.9,30.4z'/>
          <circle fill='#FFFFFF' cx='97.5' cy='97.9' r='9'/>
          <path fill='#FFFFFF' d='M97.5,19.3L97.5,19.3c-4.6,0-8.4,3.8-8.4,8.4v46.6c0,4.6,3.8,8.4,8.4,8.4h0c4.6,0,8.4-3.8,8.4-8.4V27.7
            C105.9,23.1,102.1,19.3,97.5,19.3z'/>
        </g>
      </g>
    </svg>
  </div>
}

export default Logo
