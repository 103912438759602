import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './locales/en/translation.json'
import es from './locales/es/translation.json'
import et from './locales/et/translation.json'
import fi from './locales/fi/translation.json'
import it from './locales/it/translation.json'
import no from './locales/no/translation.json'
import sv from './locales/sv/translation.json'


const resources = {
  en: { translation: en },
  es: { translation: es },
  et: { translation: et },
  fi: { translation: fi },
  sv: { translation: sv },
  no: { translation: no },
  it: { translation: it },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    load: 'languageOnly',
    fallbackLng: 'en',
    debug: !['viivi.dev','viivi.health'].includes(window.location.hostname),
    returnEmptyString: false
  })

i18n.changeLanguage()

export default i18n
