import React, { CSSProperties, Fragment, PureComponent } from 'react'


export class MirrorIcon extends PureComponent<{ disabled: boolean }> {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
      >
        {this.props.disabled ? this.renderDisabled() : this.renderEnabled()}
      </svg>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  renderEnabled() {
    return (
      <Fragment>
        <g>
          <circle cx="25" cy="25" r="25" style={{ fill: '#fff' }} />
          <circle
            cx="25"
            cy="25"
            r="24.5"
            style={{ fill: 'none', stroke: '#cecece', strokeWidth: 1 }}
          />
        </g>
        <g>
          <path
            d="M11.6283,35.9736V14.0262a1.3339,1.3339,0,0,1,2.4519-.7277l7.1427,10.9737a1.3342,1.3342,0,0,1,0,1.4554L14.08,36.7013A1.3339,1.3339,0,0,1,11.6283,35.9736Z"
            style={style.icon}
          />
          <path
            d="M38.3717,35.9736V14.0262a1.3339,1.3339,0,0,0-2.4519-.7277L28.7771,24.2722a1.3342,1.3342,0,0,0,0,1.4554L35.92,36.7013A1.3339,1.3339,0,0,0,38.3717,35.9736Z"
            style={style.icon}
          />
          <rect x="24.9" y="10" width="0.2" height="30" style={style.icon} />
        </g>
      </Fragment>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  renderDisabled() {
    return (
      <Fragment>
        <path
          d="M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z"
          style={{ fill: '#d93025' }}
        />
        <g>
          <path
            d="M11.6283,35.9736V14.0262a1.3339,1.3339,0,0,1,2.4519-.7277l7.1427,10.9737a1.3342,1.3342,0,0,1,0,1.4554L14.08,36.7013A1.3339,1.3339,0,0,1,11.6283,35.9736Z"
            style={{ ...style.icon, stroke: '#fff' }}
          />
          <path
            d="M38.3717,35.9736V14.0262a1.3339,1.3339,0,0,0-2.4519-.7277L28.7771,24.2722a1.3342,1.3342,0,0,0,0,1.4554L35.92,36.7013A1.3339,1.3339,0,0,0,38.3717,35.9736Z"
            style={{ ...style.icon, stroke: '#fff' }}
          />
          <rect
            x="24.9"
            y="10"
            width="0.2"
            height="30"
            style={{ ...style.icon, stroke: '#fff' }}
          />
        </g>
      </Fragment>
    )
  }
}

const style: { icon: CSSProperties } = {
  icon: {
    fill: 'none',
    stroke: '#333',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 2,
  },
}
