import React, { Fragment, PureComponent } from 'react'


export class CameraIcon extends PureComponent<{ disabled: boolean }> {
  render () {
    return <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'>
      { this.props.disabled ? this.renderDisabled() : this.renderEnabled() }
    </svg>
  }

  // eslint-disable-next-line class-methods-use-this
  renderDisabled () {
    return <Fragment>
      <g id='camera_off' transform='translate(-624 -646)'>
        <path id='Path_82' data-name='Path 82' d='M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z' transform='translate(624 646)' fill='#d93025'/>
        <g id='Group_52' data-name='Group 52' transform='translate(-65.379 324.177)'>
          <line id='Line_5' data-name='Line 5' x2='27.788' y2='28.38' transform='translate(700.485 332.824)' fill='none' stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
          <path id='Path_58' data-name='Path 58' d='M725.65,351.162l-6.207,2.091V352.5a2.828,2.828,0,0,0-2.729-2.917H703.108a2.828,2.828,0,0,0-2.729,2.917v12a2.828,2.828,0,0,0,2.729,2.917h13.606a2.828,2.828,0,0,0,2.729-2.917v-.757l6.207,2.091c1.448.5,2.729-1.306,2.729-2.917v-8.83C728.379,352.468,727.277,350.661,725.65,351.162Z' transform='translate(0 -11.481)' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='2'/>
        </g>
      </g>
    </Fragment>
  }

  // eslint-disable-next-line class-methods-use-this
  renderEnabled () {
    return <Fragment>
      <g id='camera_on' transform='translate(-552 -646)'>
        <g id='Ellipse_23' data-name='Ellipse 23' transform='translate(552 646)' fill='#fff' stroke='#cecece' strokeWidth='1'>
          <circle cx='25' cy='25' r='25' stroke='none'/>
          <circle cx='25' cy='25' r='24.5' fill='none'/>
        </g>
        <path id='Path_64' data-name='Path 64' d='M606.056,351.161l-6.19,2.09v-.756a2.823,2.823,0,0,0-2.721-2.916H583.578a2.823,2.823,0,0,0-2.721,2.916v11.99a2.823,2.823,0,0,0,2.721,2.916h13.567a2.823,2.823,0,0,0,2.721-2.916v-.757l6.19,2.09c1.444.5,2.721-1.305,2.721-2.916v-8.826C608.777,352.466,607.678,350.661,606.056,351.161Z' transform='translate(-17.357 312.921)' fill='none' stroke='#333' strokeMiterlimit='10' strokeWidth='2'/>
      </g>
    </Fragment>
  }
}
