import * as mobxReact from 'mobx-react-lite'
import React, { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
import { useLocalSettings } from '../../store/LocalSettings'
import { useSelectedStream } from '../../context/SelectedStream'

type Props = { stream: MediaStream }

function StreamContainer(props: PropsWithChildren<Props>) {
  const { selectedStream } = useSelectedStream()
  const { cameraStream, screenShareStream } = useLocalSettings()

  const isLocal = [cameraStream, screenShareStream].includes(props.stream)
  const className = isLocal
    ? 'video-view local-video-view'
    : 'video-view remote-video-view'

  const render = () => <div className={className}>{props.children}</div>

  const focusedStreamElem = document.querySelector('.focused-stream, .mobile-focused-stream')
  return (selectedStream === props.stream && !!focusedStreamElem)
    ? createPortal(render(), focusedStreamElem)
    : render()
}

export default mobxReact.observer(StreamContainer)
