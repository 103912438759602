import React, { PropsWithChildren } from 'react'

import './Card.css'
import Logo from '../Logo'


type Props = PropsWithChildren<{
  noLogo?: boolean
}>


function Card(props: Props) {
  return <div className="card">
    {!props.noLogo && <Logo/>}
    <p>{props.children}</p>
  </div>
}

export default Card
