import React, { Fragment, PureComponent } from 'react'


type StyleType = {
  a: Record<string, unknown>,
  b: Record<string, unknown>,
  c: Record<string, unknown>,
  d: Record<string, unknown>,
}


export class ScreenshareIcon extends PureComponent<{ active?: boolean, blocked?: boolean }> {
  render () {
    const { active, blocked } = this.props
    let s = defaultStyle
    if (active) s = activeStyle
    else if (blocked) s = blockedStyle

    return <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'>
      { this.renderContent(s) }
    </svg>
  }

  // eslint-disable-next-line class-methods-use-this
  renderContent (style: StyleType) {
    return <Fragment>
      <g transform='translate(465 2117)'>
        <g style={style.a} transform='translate(-465 -2117)'>
          <circle style={style.c} cx='25' cy='25' r='25'/>
          <circle style={style.d} cx='25' cy='25' r='24.5'/>
        </g>
        <g transform='translate(-646.5 -2450)'>
          <line style={style.b} y2='17.262' transform='translate(193.5 348.392)'/>
          <line style={style.b} x1='25' transform='translate(194 348)'/>
          <line style={style.b} y1='17.262' transform='translate(219 348.392)'/>
          <line style={style.b} x1='7.062' transform='translate(193.5 365.654)'/>
          <line style={style.b} x2='7.062' transform='translate(211.938 365.654)'/>
          <line style={style.b} y2='12.077' transform='translate(206.839 357.5)'/>
          <g transform='translate(202.523 356.689)'>
            <line style={style.b} y2='6.125' transform='translate(4.331 0) rotate(45)'/>
            <line style={style.b} y1='6.125' transform='translate(8.706 4.331) rotate(135)'/>
          </g>
          <line style={style.b} x2='11.769' transform='translate(200.562 370.362)'/>
        </g>
      </g>
    </Fragment>
  }

}

const defaultStyle = {
  a: { fill:'#fff', stroke:'#cecece', },
  b: { fill:'none', stroke:'#333', strokeLinecap:'round', strokeWidth: 2, },
  c: { stroke:'none', },
  d: { fill: 'none', }
}

const activeStyle = {
  ...defaultStyle,
  a: { fill:'#07d13d', stroke:'#cecece' },
  b: { fill:'none', stroke:'#fff', strokeLinecap:'round', strokeWidth: 2, },
}

const blockedStyle = {
  ...defaultStyle,
  a: { fill:'#c1bdbd', stroke:'#cecece' },
  b: { fill:'none', stroke:'#fff', strokeLinecap:'round', strokeWidth: 2, },
}
