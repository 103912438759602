import './Popover.less'
import React from 'react'

type Props = {
  visible: boolean
  onClose?: () => void
}

const Popover: React.FC<Props> = ({children, visible, onClose}) => {
  if (!visible) return null
  return <div role='dialog' className='popover'>
    <button className='popover__close-button' onClick={onClose}>&times;</button>
    {children}
  </div>
}

export default Popover