import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router'

import Logo from '../../components/Logo'
import PinCodeInput from './PinCodeInput'

function PinCodePrompt() {
  const [room, setRoom] = useState<string>('')
  const navigate = useNavigate()

  return (
    <div className="application">
      <div className="pin-code-view paged">
        <header>
          <Logo />
        </header>
        <section className="adjacent">
          <h1>
            <Trans i18nKey="pinCodeView.title">Join meeting by PIN code</Trans>
          </h1>
          <PinCodeInput onChange={setRoom} />
          <button
            className="button large"
            onClick={() => navigate(room)}
            disabled={room.length < 6}
          >
            <Trans i18nKey="pinCodeView.continue">Continue</Trans>
          </button>
        </section>
      </div>
    </div>
  )
}

export default PinCodePrompt
