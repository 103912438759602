import React, { createContext, useContext, useState } from 'react'

interface SelectedStream {
  selectedStream?: MediaStream
  setSelectedStream?: (stream: MediaStream) => void
}

const SelectedStreamContext = createContext<SelectedStream>({})

export const SelectedStreamProvider: React.FC = ({children}) => {
  const [selectedStream, setSelectedStream] = useState<MediaStream>()

  return <SelectedStreamContext.Provider value={{
    selectedStream,
    setSelectedStream
  }}>
    {children}
  </SelectedStreamContext.Provider>
}

export const useSelectedStream = () => useContext(SelectedStreamContext)
