import * as mobxReact from 'mobx-react-lite'
import React, { useState } from 'react'

import { ChangeLayoutIcon } from '../../components/icons'
import { LocalUser } from '../../components/Video/LocalUser'
import { RemoteUser } from '../../components/Video/RemoteUser'
import { useWebRTCState } from '../../store/WebRTCState'

function MobileScreenShareLayout() {
  const webrtc = useWebRTCState()
  const [gridView, setGridView] = useState<boolean>(false)

  return <>
    <main className="mobile-focused-stream"/>
    <section className={`streams ${gridView ? 'opened' : 'closed'}`}>
      <LocalUser/>
      {webrtc.remoteUserIds.map(userId => <RemoteUser key={userId} userId={userId} />)}
    </section>
    <nav className="floating-menu">
      <button onClick={() => setGridView(!gridView)}>
        <ChangeLayoutIcon tiled={gridView} />
      </button>
    </nav>
  </>
}

export default mobxReact.observer(MobileScreenShareLayout)
