import * as mobx from 'mobx'
import * as mobxReact from 'mobx-react-lite'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CameraToggleButton, ChangeCameraButton, MicrophoneToggleButton } from '../../components/Controls'
import Logo from '../../components/Logo'
import PreviewViewForm from './PreviewViewForm'
import { Video } from '../../components/Video/Video'
import Warning from './Warning'
import { useIsMobile } from '../../hooks/useIsMobileDevice'
import { useLocalSettings } from '../../store/LocalSettings'


type Props = {
  joinCall: (name: string) => void
}

function PreviewView(props: Props) {
  const localSettings = useLocalSettings()
  const { t } = useTranslation()
  const isMobile = useIsMobile(window)

  const [videoHeight, setVideoHeight] = useState<number>()
  const headerRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  /**
   * Calculates video height so that form content on screen should always be visible.
   */
  const calculateAndSetVideoHeight = useCallback(() => {
    const header = headerRef.current
    const form = formRef.current
    if (!isMobile || !header || !form) return setVideoHeight(undefined)

    // eslint-disable-next-line
    const calc = header!.clientHeight + form!.clientHeight
    const screenSize = window.visualViewport.height
    setVideoHeight(screenSize - calc - 30)
  }, [isMobile])

  useEffect(() => {
    calculateAndSetVideoHeight()
    window.addEventListener('resize', calculateAndSetVideoHeight)
    return () => {
      window.removeEventListener('resize', calculateAndSetVideoHeight)
    }
  }, [calculateAndSetVideoHeight])

  React.useEffect(() => {
    localSettings.updateDevices().then(mobx.action(() => {
      localSettings.setCamera(localSettings.videoDevices[0]?.deviceId)
      localSettings.setMicrophone(localSettings.audioDevices[0]?.deviceId)
      localSettings.refreshCameraStream()
    }))
  }, [])

  // If permissions to media devices are not given before enumerateDevices call,
  // enumerateDevices will return list of devices with limited information.
  // This effect updates devices list after setting initial camera, so dropdown doesn't
  // display unknown devices.
  React.useEffect(() => {
    const deviceLabels = localSettings.devices.map(device => device.label)
    const filteredDeviceLabels = deviceLabels.filter(label => label)

    if (deviceLabels.length && !filteredDeviceLabels.length) {
      console.info('No device labels exist. Refreshing devices.')
      localSettings.updateDevices()
    }
  }, [localSettings.cameraStream])

  return (
    <div className="camera-selection" id="camera-selection">
      <Warning/>
      <header ref={headerRef}>
        <Logo />
      </header>
      <section className="adjacent">
        <div className="camera-selection-video">
          <div className={!localSettings.cameraStream.active ? 'camera-selection-video-placeholder' : ''}>
            {localSettings.cameraStream.active
              ? <Video stream={localSettings.cameraStream} muted={true} style={{ height: videoHeight }} />
              : localSettings.error
                ? <p>{localSettings.error}</p>
                : <p>{t('previewView.permissionsInfo')}</p>}
          </div>

          <nav className="inline-menu" role="settings">
            <ChangeCameraButton />
            <CameraToggleButton />
            <MicrophoneToggleButton />
          </nav>
        </div>

        <PreviewViewForm joinCall={props.joinCall} ref={formRef} />
      </section>
    </div>
  )
}

export default mobxReact.observer(PreviewView)
