import { useEffect, useState } from 'react'


export function useIsMobile(window: Window) {
  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleChange)
    return () => {
      window.removeEventListener('resize', handleChange)
    }
  })

  return width <= 768
}
