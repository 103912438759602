import React, { useEffect, useState } from 'react'


type Props = {
  onBackspace: () => void
  onInput: () => void
}

function PinCodeItem(props: Props) {
  const [previous, setPrevious] = useState<string>('')
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    if (previous && !value) props.onBackspace()
    else if (previous !== value) props.onInput()
  }, [value, previous])

  function handleInput(e: React.ChangeEvent<HTMLInputElement>) {
    setPrevious(value)
    setValue(e.target.value)
  }

  return <input
    type='text'
    inputMode='numeric'
    pattern='[0-9]*'
    maxLength={1}
    onChange={handleInput}
  />
}

export default PinCodeItem
