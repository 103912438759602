import * as mobxReact from 'mobx-react-lite'
import React, { useMemo } from 'react'

import StreamContainer from './StreamContainer'
import { Video } from './Video'
import { useWebRTCState } from '../../store/WebRTCState'

interface Props extends React.HTMLProps<HTMLVideoElement> {
  userId: string
}

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1) + '...'

export const RemoteUser = mobxReact.observer((props: Props) => {
  const { peers } = useWebRTCState()
  const peer = useMemo(() => peers.get(props.userId), [props.userId])

  if (!peer) return null

  const streams = peer.streams.filter(stream => [peer.cameraStream, peer.screenShareStream].includes(stream.id))

  return <>
    {streams.map(stream => <StreamContainer key={stream.id} stream={stream}>
      { peer.connectionState === 'connected'
        ? <Video
          key={stream.id}
          stream={stream}
          className='video remote-video'/>
        :
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          fontWeight: 700,
          fontSize: 32
        }}>
          { peer.connectionState ? capitalize(peer.connectionState) : 'Initializing...' }
        </div>
      }
      <span className="video-details remote-details">
        <span className="name">{peer.name}</span>
      </span>
    </StreamContainer>)
    }
  </>
})
