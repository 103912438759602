import React, { CSSProperties } from 'react'


export function ChatIcon() {
  return <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'>
    <g id='chat_status' transform='translate(-1296 -645)'>
      <g id='Ellipse_27' data-name='Ellipse 27' transform='translate(1296 645)' fill='#fff' stroke='#cecece' strokeWidth='1'>
        <circle cx='25' cy='25' r='25' stroke='none'/>
        <circle cx='25' cy='25' r='24.5' fill='none'/>
      </g>
      <g id='Group_49' data-name='Group 49'>
        <g id='Group_47' data-name='Group 47' transform='translate(1304.625 653.625)'>
          <path id='Path_38' data-name='Path 38'
            d='M17.461,4.375A11.434,11.434,0,0,0,7.737,21.851L4.375,28.912l7.06-3.362A11.451,11.451,0,1,0,17.461,4.375Z'
            transform='translate(0 0)' style={ style }
          />
        </g>
        <line id='Line_11' data-name='Line 11' x2='10.001' transform='translate(1317 667.5)' style={ style } />
        <line id='Line_13' data-name='Line 13' x2='10.001' transform='translate(1317 671.5)' style={ style } />
      </g>
    </g>
  </svg>
}

const style: CSSProperties = {
  fill:           'none',
  stroke:         '#333',
  strokeLinecap:  'round',
  strokeLinejoin: 'round',
  strokeWidth:    2,
}
