import React from 'react'


function SendMessageIcon() {
  return <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>
    <path id='chat_send' d='M-2348-5632h0v-11.055l12.308-3.944-12.308-3.945V-5662l30,15-30,15Z' transform='translate(2348 5662)' fill='#fff'/>
  </svg>
}

export { SendMessageIcon }
