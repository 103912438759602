import * as mobx from 'mobx'
import * as mobxReact from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CameraIcon, CameraSwitchIcon, ChatIcon, EndCallIcon, MenuIcon, MicrophoneIcon, ScreenshareIcon, SettingsIcon } from './icons'
import DeviceSettings from './DeviceSettings'
import Popover from './Popover'
import { SocketConnectedContext } from '../context/Socket'
import { handleMediaDevicesError } from '../utils/devices'
import { useLocalSettings } from '../store/LocalSettings'
import { useWebRTCState } from '../store/WebRTCState'

type ControlButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  dataControlButton?: boolean
}

function ControlButton({ dataControlButton = true, ...props }: ControlButtonProps) {
  return <button
    data-control-button={dataControlButton || undefined}
    {...props}
    className={`control-button ${props.className || ''}`}
  />
}

export function MenuButton(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const { t } = useTranslation()
  return <ControlButton {...props} title={t('footer.menu')}>
    <MenuIcon/>
  </ControlButton>
}

export const MicrophoneToggleButton = mobxReact.observer((props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { t } = useTranslation()
  const localSettings = useLocalSettings()

  return <ControlButton {...props} title={t('footer.toggleMicrophone')} onClick={localSettings.toggleMicrophone}>
    <MicrophoneIcon disabled={!localSettings.microphoneEnabled} />
  </ControlButton>
})

export const CameraToggleButton = mobxReact.observer((props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { t } = useTranslation()
  const localSettings = useLocalSettings()

  return <ControlButton {...props} title={t('footer.toggleCamera')} onClick={localSettings.toggleCamera}>
    <CameraIcon disabled={!localSettings.cameraEnabled} />
  </ControlButton>
})

export function ChatToggleButton(props: React.ButtonHTMLAttributes<HTMLButtonElement> & {unreadMessages?: number}) {
  const { t } = useTranslation()
  // TODO: Perhaps replace the whole props logic with something else to prevent hacky stuff like this
  const propsWithoutUnread = {...props}
  delete propsWithoutUnread['unreadMessages']

  return <ControlButton {...propsWithoutUnread} title={t('footer.openChat')}>
    <ChatIcon />
    {props.unreadMessages && props.unreadMessages > 0 ? <span className="unread-indicator">{props.unreadMessages}</span> : null}
  </ControlButton>
}

export function EndCallButton(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const { t } = useTranslation()
  return <ControlButton {...props} title={t('footer.endCall')}>
    <EndCallIcon/>
  </ControlButton>
}

export const ScreenShareButton = mobxReact.observer(() => {
  const { t } = useTranslation()
  const { screenShareStream, stopScreenShareStream, setScreenShareStream } = useLocalSettings()
  const { hasScreenShareStream: hasRemoteScreenshare } = useWebRTCState()
  const socketConnected = React.useContext(SocketConnectedContext)

  if (typeof navigator.mediaDevices.getDisplayMedia !== 'function') return null

  function handleClick() {
    if (screenShareStream) return stopScreenShareStream()
    if (typeof navigator.mediaDevices.getDisplayMedia === 'function')
      navigator.mediaDevices.getDisplayMedia().then(setScreenShareStream).catch(handleMediaDevicesError)
  }

  let className = 'screen-share-button'
  if (screenShareStream) className += ' enabled'
  if (hasRemoteScreenshare) className += ' muted'

  return <ControlButton
    title={t('footer.toggleScreen')}
    className={className}
    onClick={handleClick}
    disabled={(!screenShareStream && !socketConnected) || hasRemoteScreenshare}
  >
    <ScreenshareIcon active={!!screenShareStream} blocked={hasRemoteScreenshare} />
  </ControlButton>
})

export const ChangeCameraButton = mobxReact.observer(() => {
  const { t } = useTranslation()
  const localSettings = useLocalSettings()
  const socketConnected = React.useContext(SocketConnectedContext)

  if (localSettings.videoDevices.length <= 1)
    return null

  const handleClick = mobx.action(() => {
    const currentIndex = localSettings.videoDevices.findIndex(device => device.deviceId  === localSettings.camera)
    const nextIndex = (currentIndex + 1) % localSettings.videoDevices.length

    localSettings.setCamera(localSettings.videoDevices[nextIndex].deviceId)
    localSettings.refreshCameraStream()
  })

  return <ControlButton title={t('footer.changeCamera')} onClick={handleClick} disabled={!socketConnected}>
    <CameraSwitchIcon/>
  </ControlButton>
})

export const DeviceSettingsButton = mobxReact.observer(() => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const localSettings = useLocalSettings()

  function handleClick() {
    if (!visible)
      localSettings.updateDevices()

    setVisible(!visible)
  }

  return <div data-control-button={true}>
    <Popover visible={visible} onClose={() => setVisible(false)}>
      <h4>{t('footer.deviceSettingsTitle')}</h4>
      <form className='devices-form'>
        <DeviceSettings/>
      </form>
    </Popover>
    <ControlButton dataControlButton={false} title={t('footer.openDeviceSettings')} onClick={handleClick}>
      <SettingsIcon/>
    </ControlButton>
  </div>
})
