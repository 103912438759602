import * as React from 'react'
import * as Sentry from '@sentry/react'
import Logo from './Logo'


type State = {
  hasError: boolean
  errorName: string
  errorCode?: number | null
  errorMessage: string
}

type Props = React.PropsWithChildren<{
  handleRejoin: () => void
}>

type SimplePeerError = {
  name: string
  message: string
  stack?: string
  details?: string
  code?: number
}

class ErrorBoundaryView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      errorName: '',
      errorMessage: '',
      errorCode: null
    }
  }

  componentDidCatch(error: SimplePeerError): void {
    this.setState({
      hasError: true,
      errorName: error.name,
      errorMessage: error.message,
      errorCode: error.code ? error.code : null
    })
    Sentry.captureException(error)
  }

  handleRejoin = (): void => {
    this.setState({ hasError: false })
    this.props.handleRejoin()
  }

  renderErrorView(): React.ReactNode {
    const { errorCode, errorName, errorMessage } = this.state

    return <div className="application">
      <div className='error-boundary-view'>
        <header>
          <Logo />
        </header>

        <h1>{`Error has occured ${errorName}`}</h1>

        <p>{`Error message: ${errorMessage}`}</p>

        {errorCode && <p>{`Error code: ${errorCode}`}</p> }

        <button className="button" onClick={this.handleRejoin}>
          Rejoin
        </button>
      </div>
    </div>
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return this.renderErrorView()
    }

    return this.props.children
  }
}

export default ErrorBoundaryView
