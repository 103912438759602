import 'react-toastify/dist/ReactToastify.css'
import * as React from 'react'
import * as Sentry from '@sentry/react'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { useParams } from 'react-router'

import { LocalSettings, LocalSettingsContext } from '../store/LocalSettings'
import EndCallView from './EndCallView'
import ErrorBoundaryView from '../components/ErrorBoundaryView'
import PreviewView from './PreviewView'
import SocketContainer from '../components/SocketContainer'
import VideoCallView from './VideoCallView'
import { useBrand } from '../context/Brand'


enum View { PREVIEW, CALL, END }

function Room() {
  const params = useParams()
  const [localSettings] = React.useState(() => new LocalSettings())
  const [view, setView] = React.useState<View>(View.PREVIEW)
  const [error, setError] = React.useState<Error>()
  const { color } = useBrand()

  React.useEffect(() => {
    Sentry.setTag('room', params.roomNumber)
    Sentry.setTag('connectionId', !!params.connectionId)
  }, [])

  React.useEffect(() => {
    document.documentElement.style.setProperty('--brand-color', color)
  }, [color])

  function handleJoinCall() {
    console.info('Changing view to VideoCallView.')
    setView(View.CALL)
  }

  function handleEndCall(error?: Error) {
    console.info('Changing view to EndCallView.')

    setError(error)
    setView(View.END)
  }

  function handleRejoin() {
    console.info('Changing view to PreviewView.')

    setView(View.PREVIEW)
  }

  function renderView() {
    switch(view) {
    case View.PREVIEW:
      return <PreviewView joinCall={handleJoinCall} />
    case View.CALL:
      return <SocketContainer><VideoCallView endCall={handleEndCall}/></SocketContainer>
    case View.END:
      return <EndCallView error={error} rejoin={handleRejoin}/>
    }
  }

  return  (
    <ErrorBoundaryView handleRejoin={handleRejoin}>
      <LocalSettingsContext.Provider value={localSettings}>
        <div className='application'>
          <ToastContainer
            position={toast.POSITION.TOP_CENTER}
            autoClose={4000}
            hideProgressBar
            transition={Slide}
            toastStyle={{ backgroundColor: 'rgba(50, 50, 50, .7)', color: '#fff' }}
            pauseOnFocusLoss={false}
            pauseOnHover={false}
            draggable={false}
          />
          {renderView()}
        </div>
      </LocalSettingsContext.Provider>
    </ErrorBoundaryView>
  )
}

export default Room
