import * as mobx from 'mobx-react-lite'
import React from 'react'

import { LocalUser } from '../../components/Video/LocalUser'
import { RemoteUser } from '../../components/Video/RemoteUser'
import { useWebRTCState } from '../../store/WebRTCState'


function GridLayout() {
  const webrtc = useWebRTCState()

  return (
    <section className="streams">
      <LocalUser/>
      {webrtc.remoteUserIds.map(userId => <RemoteUser key={userId} userId={userId} />)}
    </section>
  )
}

export default mobx.observer(GridLayout)
