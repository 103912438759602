import React, { Fragment, PureComponent } from 'react'


export class MicrophoneIcon extends PureComponent<{ disabled: boolean }> {
  render () {
    return <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'>
      { this.props.disabled ? this.renderDisabled() : this.renderEnabled() }
    </svg>
  }

  // eslint-disable-next-line class-methods-use-this
  renderDisabled () {
    return <Fragment>
      <g id='microphone_off' transform='translate(-693 -646)'>
        <path id='Path_83' data-name='Path 83' d='M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z' transform='translate(693 646)' fill='#d93025'/>
        <g id='Group_51' data-name='Group 51' transform='translate(227.818 437.064)'>
          <path id='Path_56' data-name='Path 56' d='M500.352,278.192a9.849,9.849,0,0,1-9.849,9.849h-.382a9.849,9.849,0,0,1-9.849-9.849' transform='translate(-0.069 -44.633)' fill='none' stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
          <rect id='Rectangle_13' data-name='Rectangle 13' width='11.996' height='19.439' rx='5.998' transform='translate(484.245 219.935)' strokeWidth='2' stroke='#fff' strokeLinecap='round' strokeLinejoin='round' fill='none'/>
          <path id='Path_57' data-name='Path 57' d='M523.206,322.329V326.6' transform='translate(-32.963 -78.449)' fill='none' stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
          <line id='Line_4' data-name='Line 4' x2='20.724' y2='21.165' transform='translate(480.182 219.977)' fill='none' stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
        </g>
      </g>
    </Fragment>
  }

  // eslint-disable-next-line class-methods-use-this
  renderEnabled () {
    return <Fragment>
      <g id='microphone_on' transform='translate(-622 -646)'>
        <g id='Ellipse_22' data-name='Ellipse 22' transform='translate(622 646)' fill='#fff' stroke='#cecece' strokeWidth='1'>
          <circle cx='25' cy='25' r='25' stroke='none'/>
          <circle cx='25' cy='25' r='24.5' fill='none'/>
        </g>
        <g id='Group_40' data-name='Group 40' transform='translate(0 -1)'>
          <g id='Group_19' data-name='Group 19' transform='translate(168.309 457.049)'>
            <path id='Path_49' data-name='Path 49' d='M489.037,257.5a9.979,9.979,0,0,1-9.979,9.979h-.387a9.979,9.979,0,0,1-9.979-9.979' transform='translate(0 -43.152)' fill='none' stroke='#333' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
            <rect id='Rectangle_6' data-name='Rectangle 6' width='12.155' height='19.697' rx='6.077' transform='translate(472.786 200.951)' strokeWidth='2' stroke='#333' strokeLinecap='round' strokeLinejoin='round' fill='none'/>
            <path id='Path_50' data-name='Path 50' d='M511.624,301.64v4.328' transform='translate(-32.76 -76.831)' fill='none' stroke='#333' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
          </g>
        </g>
      </g>
    </Fragment>
  }

}
