import React from 'react'
import { Trans } from 'react-i18next'

import Logo from '../components/Logo'


type Props = {
  error?: Error
  rejoin: () => void
}

function EndCallView(props: Props) {
  return (
    <div id="post-video-call">
      <header>
        <Logo />
      </header>

      {props.error ? (
        <h1>
          <Trans i18nKey="postCall.error">
            An error occurred, please try to rejoin the call.
          </Trans>
        </h1>
      ) : (
        <h1>
          <Trans i18nKey="postCall.header">You have left the meeting</Trans>
        </h1>
      )}

      {!!props.error && <p>{props.error.message}</p>}

      <button className="button" onClick={props.rejoin}>
        <Trans i18nKey="postCall.rejoin">Rejoin</Trans>
      </button>
    </div>
  )
}

export default EndCallView
