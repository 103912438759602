import React, { useRef } from 'react'
import PinCodeItem from './PinCodeItem'


type Props = {
  onChange: (value: string) => void
}

function PinCodeInput(props: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null)

  function getInputs(): HTMLInputElement[] {
    if (!wrapperRef.current) return []
    return Array
      .from(wrapperRef.current.children)
      .filter((c) => c.tagName === 'INPUT') as HTMLInputElement[]
  }

  function handleBackspace(index: number) {
    updateValue()
    if (index > 0) getInputs()[index - 1].focus()
  }

  function handleInput(index: number) {
    updateValue()
    const inputs = getInputs()
    if (index < inputs.length - 1) inputs[index + 1].focus()
  }

  function updateValue() {
    const inputs = getInputs()
    if (!inputs.length) return
    props.onChange(inputs.map(i => i.value).join(''))
  }

  const items = [0,1,2,3,4,5].map(index =>
    <PinCodeItem
      key={index}
      onBackspace={() => handleBackspace(index)}
      onInput={() => handleInput(index)}
    />)

  items.splice(items.length / 2, 0, <span key='span'>-</span>)

  return <div className="pin-code-input" ref={wrapperRef}>
    {items}
  </div>
}

export default PinCodeInput
