import * as mobxReact from 'mobx-react-lite'
import { LocalUser } from '../../components/Video/LocalUser'
import React from 'react'
import { RemoteUser } from '../../components/Video/RemoteUser'
import { useWebRTCState } from '../../store/WebRTCState'

function DesktopScreenShareLayout() {
  const webrtc = useWebRTCState()

  return <>
    <main className='focused-stream'/>
    <aside className='streams'>
      <LocalUser/>
      {webrtc.remoteUserIds.map(userId => <RemoteUser key={userId} userId={userId} />)}
    </aside>
  </>
}

export default mobxReact.observer(DesktopScreenShareLayout)
