import {
  CameraToggleButton,
  ChangeCameraButton,
  ChatToggleButton,
  DeviceSettingsButton,
  EndCallButton,
  MenuButton,
  MicrophoneToggleButton,
  ScreenShareButton,
} from '../../components/Controls'
import React, { useState } from 'react'
import EndCallDialog from './EndCallDialog'
import Logo from '../../components/Logo'


type Props = {
  openChat: () => void
  onEndCall: () => void
  unreadMessages: number
}

function Footer(props: Props) {
  const [endCallVisible, setEndCallVisible] = useState<boolean>(false)
  const [controlsExpanded, setControlsExpanded] = useState<boolean>(false)

  return <footer id="page-footer">
    <EndCallDialog onLeave={props.onEndCall} onCancel={() => setEndCallVisible(false)} visible={endCallVisible} />
    <Logo />

    <div className="centered-buttons">
      <ScreenShareButton />
      <div className={`stream-control-buttons ${controlsExpanded ? 'active' : ''}`}>
        <ChangeCameraButton />
        <CameraToggleButton />
        <MicrophoneToggleButton />
        <DeviceSettingsButton />
        <MenuButton onClick={() => setControlsExpanded(!controlsExpanded)} />
      </div>
      <EndCallButton onClick={() => setEndCallVisible(true)} />
    </div>

    <ChatToggleButton id="chat-toggle" onClick={props.openChat} unreadMessages={props.unreadMessages} />
  </footer>
}

export default Footer
