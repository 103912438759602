import React from 'react'

export const SettingsIcon = () => (
  <svg viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg" style={{
    height: 28,
    width: 48,
    padding: '10px 0',
    border: '1px solid rgb(206,206,206)',
    borderRadius: '50%',
    backgroundColor: '#fff'
  }}>
    <path
      d="m52.794 23.968 4.2-10.808A9.688 9.688 0 0 1 66.066 7h7.868a9.688 9.688 0 0 1 9.03 6.16l4.2 10.808 14 8.12 11.48-1.736a9.688 9.688 0 0 1 9.8 4.732l3.948 6.832a9.702 9.702 0 0 1-.826 10.892l-7.238 9.072v16.24l7.238 9.072a9.702 9.702 0 0 1 .826 10.892l-3.948 6.832a9.688 9.688 0 0 1-9.8 4.732l-11.48-1.736-14 8.12-4.2 10.808a9.688 9.688 0 0 1-9.03 6.16h-7.868a9.688 9.688 0 0 1-9.03-6.16l-4.2-10.808-14-8.12-11.48 1.736a9.688 9.688 0 0 1-9.8-4.732l-4.088-6.832a9.702 9.702 0 0 1 .826-10.892l7.238-9.072V61.88l-7.238-9.072a9.702 9.702 0 0 1-.826-10.892l3.948-6.832a9.688 9.688 0 0 1 9.8-4.732l11.48 1.736zM52.5 70A17.5 17.5 0 1 0 70 52.5 17.5 17.5 0 0 0 52.5 70z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10"
    />
  </svg>
)
