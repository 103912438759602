import * as Sentry from '@sentry/react'
import React, { createContext, useContext } from 'react'
import axios, { AxiosResponse } from 'axios'
import { useParams } from 'react-router'


const BrandContext = createContext<Brand>({ logo: null, color: null, roomAvailable: null })
const useBrand = () => useContext(BrandContext)

const BrandProvider: React.FC = ({children}) => {
  const params = useParams()
  const [color, setColor] = React.useState<string | null>(null)
  const [logo, setLogo] = React.useState<string | null>(null)
  const [roomAvailable, setRoomAvailable] = React.useState<boolean | null>(null)

  React.useEffect(() => {
    function handleResponse(response: AxiosResponse) {
      if (response.status === 200) {
        setRoomAvailable(true)
        if (response.data.brand_color) setColor(response.data.brand_color)
        if (response.data.brand_logo_url) setLogo(response.data.brand_logo_url)
      } else {
        setRoomAvailable(false)
      }
    }

    function handleError(error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 404)
        console.warn('Room not found.')
      else {
        console.error('Fetching room data failed.', error)
        Sentry.captureException(error)
      }

      setRoomAvailable(false)
    }

    axios.get(`/api/get_room_data?room=${params.roomNumber}`)
      .then(handleResponse)
      .catch(handleError)
  }, [])


  return <BrandContext.Provider value={{color, logo, roomAvailable}}>
    {children}
  </BrandContext.Provider>
}

export { BrandContext, useBrand, BrandProvider }
