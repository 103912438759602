import React, { useEffect, useState } from 'react'
import UAParser from 'ua-parser-js'

interface KnownIssue {
  os: {
    name: string
    version: string
  }
  description: string
}

const knownIssues: KnownIssue[] = [
  {
    os: {
      name: 'iOS',
      version: '15.3'
    },
    description: 'Viivi doesn\'t work reliably with iOS 15.3. We recommend that you upgrade to the latest iOS version. You can update from the device\'s settings.'
  }
]

const TEST_USER_AGENTS = {
  IOS_15_3_1: 'Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 QuantcastSDK/iOS_1.7.0/17q11li05fgn48qm-ptk3mgrptchggpxe'
}

const Warning: React.FC = () => {
  const [osName, setOsName] = useState<string>()
  const [osVersion, setOsVersion] = useState<string>()
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    // for testing
    // const parser = new UAParser(TEST_USER_AGENTS.IOS_15_3_1)

    const parser = new UAParser(navigator.userAgent)
    const os = parser.getOS()
    setOsName(os.name)
    setOsVersion(os.version)
  }, [])

  const warning = knownIssues
    .find(({os}) => os.name === osName && osVersion?.startsWith(os.version))

  if (!warning || !visible) return null
  
  return <div style={styles.container}>
    <div>
      <h3 style={styles.header}>Please upgrade your {osName} version</h3>
      <p style={styles.paragraph}>{warning.description}</p>
    </div>
    <button style={styles.button} onClick={() => setVisible(false)}>&times;</button>
  </div>
}

type Styles = Record<string, React.CSSProperties>

const styles: Styles = {
  container: {
    color: '#721C24',
    backgroundColor: '#F8D7DA',
    padding: '0 20px',
    borderRadius: 5,
    border: '1px solid #F5C6CB',
    boxShadow: '0 2px 2px rgba(0,0,0,0.2)',
    display: 'flex'
  },
  button: {
    border: 'none',
    fontSize: 'xx-large'
  },
  header: {
    marginBottom: 5
  },
  paragraph: {
    marginTop: 0
  }
}

export default Warning