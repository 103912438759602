import * as mobx from 'mobx'
import * as mobxReact from 'mobx-react-lite'
import React from 'react'
import { isAudioOutputSupported } from '../utils/devices'
import { useLocalSettings } from '../store/LocalSettings'
import { useTranslation } from 'react-i18next'

interface DeviceSelectProps {
  devices: MediaDeviceInfo[]
  selectedDevice?: string
  onChange: (deviceId: string) => void
  label: string
  defaultDeviceLabel?: string
}

const DeviceSelect: React.FC<DeviceSelectProps> = mobxReact.observer(({
  onChange,
  devices,
  selectedDevice,
  label,
  defaultDeviceLabel
}) => {
  return <>
    <label>{label}</label>
    <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e.target.value)} value={selectedDevice}>
      {devices.map((d, index) => <option key={d.deviceId} value={d.deviceId}>
        {d.label || `${defaultDeviceLabel || 'Device'} ${index + 1}`}
      </option>)}
    </select>
  </>
})

const DeviceSettings: React.FC = () => {
  const {t} = useTranslation()
  const localSettings = useLocalSettings()

  return <>
    <DeviceSelect
      label={t('previewView.chooseCamera')}
      devices={localSettings.videoDevices}
      onChange={mobx.action(deviceId => {
        localSettings.setCamera(deviceId)
        localSettings.refreshCameraStream()
      })}
      selectedDevice={localSettings.camera}
      defaultDeviceLabel='Camera'
    />
    <DeviceSelect
      label={t('previewView.chooseMicrophone')}
      devices={localSettings.audioDevices}
      onChange={mobx.action(deviceId => {
        localSettings.setMicrophone(deviceId)
        localSettings.refreshCameraStream()
      })}
      selectedDevice={localSettings.microphone}
      defaultDeviceLabel='Microphone'
    />
    {isAudioOutputSupported() && <DeviceSelect
      label={t('previewView.chooseOutput')}
      devices={localSettings.outputDevices}
      onChange={localSettings.setOutput}
      selectedDevice={localSettings.output}
      defaultDeviceLabel='Audio output'
    />}
  </>
}

export default mobxReact.observer(DeviceSettings)
