import * as mobxReact from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'

import StreamContainer from './StreamContainer'
import { Video } from './Video'
import { useLocalSettings } from '../../store/LocalSettings'

export const LocalUser = mobxReact.observer(() => {
  const { t } = useTranslation()
  const localSettings = useLocalSettings()

  const streams = [
    localSettings.cameraStream,
    localSettings.screenShareStream as MediaStream
  ].filter(Boolean)

  return <>{streams.map(stream => <StreamContainer key={stream.id} stream={stream}>
    <Video
      style={stream === localSettings.screenShareStream ? {opacity: '15%'} : {}}
      stream={stream}
      className='video local-video'
      muted={true}
    />
    <span className='video-details local-details'>
      <span className='name'>{t('chat.you')}</span>
    </span>
  </StreamContainer>)}
  </>
})
