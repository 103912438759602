import 'webrtc-adapter'
import './index.less'
import * as Sentry from '@sentry/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import { render } from 'react-dom'

import App from './App'
import PinCodeView from './views/PinCodeView'
import i18n from './lang/i18n'
import packageJson from '../package.json'


console.info(`Viivi Health frontend version: ${packageJson.version}`)


function initSentry() {
  const viiviEnvs: {[key: string]: string} = {
    'viivi.dev': 'stage',
    'viivi.health': 'production',
  }

  if (!(window.location.hostname in viiviEnvs)) return

  Sentry.init({
    dsn: 'https://3a4b148640534f6db67487e74f22cea7@sentry.fns.fi/22',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: viiviEnvs[window.location.hostname],
    release: packageJson.name + '@' + packageJson.version,
    maxBreadcrumbs: 200,
  })
}

initSentry()

render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Routes>
        <Route path="/" element={<PinCodeView />} />
        <Route path=":roomNumber">
          <Route path=':connectionId' element={<App/>}/>
          <Route path='' element={<App/>}/>
        </Route>
      </Routes>
    </I18nextProvider>
  </BrowserRouter>,
  document.querySelector('#root')
)
