import * as mobxReact from 'mobx-react-lite'
import React, { useEffect } from 'react'

import DesktopScreenShareLayout from './DesktopScreenShareLayout'
import MobileScreenShareLayout from './MobileScreenShareLayout'
import { useIsMobile } from '../../hooks/useIsMobileDevice'
import { useLocalSettings } from '../../store/LocalSettings'
import { useSelectedStream } from '../../context/SelectedStream'
import { useWebRTCState } from '../../store/WebRTCState'


function ScreenShareLayout() {
  const localSettings = useLocalSettings()
  const { selectedStream, setSelectedStream } = useSelectedStream()
  const webrtc = useWebRTCState()
  const isMobile = useIsMobile(window)

  useEffect(() => {
    if (!setSelectedStream || selectedStream) return
    if (localSettings.screenShareStream) return setSelectedStream(localSettings.screenShareStream)

    const user = webrtc.remoteUsers.find(user => user.screenShareStream)
    if (!user)
      return

    const stream = user.streams.find(stream => stream.id === user.screenShareStream)
    if (stream)
      setSelectedStream(stream)
  }, [webrtc.remoteStreams])

  return isMobile ? <MobileScreenShareLayout/> : <DesktopScreenShareLayout/>
}

export default mobxReact.observer(ScreenShareLayout)
