import React from 'react'
import { useTranslation } from 'react-i18next'

import Overlay from '../../components/Overlay'
import { Status } from '.'


type Props = {
  status: Status
}

function StatusOverlay(props: Props) {
  const { t } = useTranslation()

  const statusMessages = {
    [Status.WAITING]: t('connectionStatus.waitForJoin'),
    [Status.CONNECTED]: ''
  }

  return <Overlay
    visible={props.status !== Status.CONNECTED}
    message={statusMessages[props.status]}
    displaySpinner
  />
}

export default StatusOverlay
