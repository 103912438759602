import * as Sentry from '@sentry/react'
import { Socket, io } from 'socket.io-client'
import { SocketConnectedContext, SocketContext } from '../../context/Socket'
import Card from '../Card'
import React from 'react'
import { toast } from 'react-toastify'
import { useLocalSettings } from '../../store/LocalSettings'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'


function generateRandomHex(size: number) {
  // Generates a random hex string, e.g. "1d1ba19531d6".
  return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')
}

function SocketContainer(props: React.PropsWithChildren<unknown>) {
  const { t } = useTranslation()
  const params = useParams()
  const [socket, setSocket] = React.useState<Socket>()
  const [socketConnected, setSocketConnected] = React.useState(false)
  const { name } = useLocalSettings()
  const [disconnectReason, setDisconnectReason] = React.useState<string>()

  React.useEffect(() => {
    const userId = generateRandomHex(8)

    const socket = io({
      path: '/socket/io/',
      timeout: 5000,
      query: {
        userId: userId,
        room: params.roomNumber,
        connectionId: params.connectionId || '',
        name: name,
      },
      autoConnect: false,
    })

    socket.io.on('reconnect', attempt => {
      console.info(`Socket reconnected on attempt: ${attempt}.`)
      toast(t('websocket.reconnect'))
    })

    socket.io.on('reconnect_attempt', attempt => {
      console.info(`Socket reconnection attempt: ${attempt}.`)
    })

    socket.io.on('reconnect_error', error => {
      console.error('Socket reconnect error.', error)
      Sentry.captureException(error)
    })

    socket.io.on('reconnect_failed', () => {
      console.error('Socket reconnection failed.')
    })

    socket.on('connect', () => {
      console.info(`Socket connected. User ID: ${userId}.`)
      setSocketConnected(true)
    })

    socket.on('message', setDisconnectReason)

    socket.on('connect_error', error => {
      console.error('Socket connection error.', error)
      Sentry.captureException(error)
    })

    socket.on('disconnect', (reason) => {
      console.info(`Socket disconnected: ${reason}.`)
      toast(t('websocket.disconnect'))
      setSocketConnected(false)
    })

    window.onbeforeunload = () => {
      console.warn('Window unloading. Closing socket.')
      socket.disconnect()
    }

    setSocket(socket)

    return () => {
      if (socket) {
        console.info('Closing socket.')
        socket.off()
        socket.disconnect()
      }
    }
  }, [])

  if (disconnectReason)
    return <Card>{disconnectReason}</Card>

  if (!socket)
    return <Card>{t('app.connecting')}</Card>

  return <SocketContext.Provider value={socket}>
    <SocketConnectedContext.Provider value={socketConnected}>
      {props.children}
    </SocketConnectedContext.Provider>
  </SocketContext.Provider>
}

export default SocketContainer
