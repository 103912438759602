import React from 'react'


export function MenuIcon() {
  return <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'>
    <g id='more_settings' transform='translate(-483 -647)'>
      <g id='Ellipse_21' data-name='Ellipse 21' transform='translate(483 647)' fill='#fff' stroke='#cecece' strokeWidth='1'>
        <circle cx='25' cy='25' r='25' stroke='none'/>
        <circle cx='25' cy='25' r='24.5' fill='none'/>
      </g>
      <g id='Group_37' data-name='Group 37' transform='translate(450.635 657.563)'>
        <path id='Path_44' data-name='Path 44' d='M54.612,107.608a2.553,2.553,0,1,0,2.553-2.59A2.572,2.572,0,0,0,54.612,107.608Z' transform='translate(0 -83.923)' fill='none' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
        <path id='Path_45' data-name='Path 45' d='M54.612,6.983a2.553,2.553,0,1,0,2.553-2.59,2.572,2.572,0,0,0-2.553,2.59Z' fill='none' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
        <path id='Path_46' data-name='Path 46' d='M54.612,57.3a2.553,2.553,0,1,0,2.553-2.59A2.572,2.572,0,0,0,54.612,57.3Z' transform='translate(0 -41.962)' fill='none' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
      </g>
    </g>
  </svg>
}
