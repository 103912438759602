import DeviceSettings from '../../components/DeviceSettings'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useLocalSettings } from '../../store/LocalSettings'
import { useTranslation } from 'react-i18next'

interface Props {
  joinCall: (name: string) => void
}

const NameInput: React.FC = observer(() => {
  const { t } = useTranslation()
  const localSettings = useLocalSettings()

  return <>
    <label htmlFor="id_name">{t('previewView.name')}</label>
    <input
      id="id_name"
      autoComplete="off"
      value={localSettings.name || ''}
      onChange={(e) => localSettings.setName(e.target.value)}
    />
  </>
})

const JoinCallButton: React.FC<Props> = observer(({joinCall}) => {
  const { t } = useTranslation()
  const localSettings = useLocalSettings()

  return <button
    disabled={!localSettings.name}
    className="btn-start button"
    onClick={() => joinCall(localSettings.name || '')}
  >
    {t('previewView.start')}
  </button>
})

// eslint-disable-next-line
const PreviewViewForm = React.forwardRef(({joinCall}: Props, ref: any) => {
  return <form className="camera-selection-form devices-form" ref={ref}>
    <NameInput/>
    <DeviceSettings/>
    <JoinCallButton joinCall={joinCall}/>
  </form>
})
PreviewViewForm.displayName = 'PreviewViewForm'

export default PreviewViewForm
