import React, { PureComponent } from 'react'

/* eslint-disable max-len */


export class ChangeLayoutIcon extends PureComponent<{ tiled?: boolean }> {

  render () {
    return this.props.tiled ? this.renderDefault() : this.renderTiled()
  }

  // eslint-disable-next-line class-methods-use-this
  renderDefault () {
    return <svg id='layout-spotlight' xmlns='http://www.w3.org/2000/svg' width='39' height='33' viewBox='0 0 39 33'>
      <g id='Rectangle_62' data-name='Rectangle 62' stroke='#fff' strokeWidth='1' opacity='0.502'>
        <rect width='39' height='33' rx='4' stroke='none'/>
        <rect x='0.5' y='0.5' width='38' height='32' rx='3.5' fill='none'/>
      </g>
      <g id='Group_122' data-name='Group 122' transform='translate(11.466 7.358)'>
        <path id='Path_95' data-name='Path 95' d='M18.958,42.306a4.389,4.389,0,1,0,4.389-4.389,4.389,4.389,0,0,0-4.389,4.389Z' transform='translate(-16.118 -37.917)' fill='none' stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
        <path id='Path_96' data-name='Path 96' d='M2.917,103.48a7.23,7.23,0,1,1,14.459,0Z' transform='translate(-2.917 -85.124)' fill='none' stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
      </g>
      <rect id='Rectangle_65' data-name='Rectangle 65' width='7' height='6' rx='1' transform='translate(28 4)' fill='#f9f9f9'/>
    </svg>
  }

  // eslint-disable-next-line class-methods-use-this
  renderTiled () {
    return <svg id='layout-tiled' xmlns='http://www.w3.org/2000/svg' width='39' height='33' viewBox='0 0 39 33'>
      <g id='Rectangle_272' data-name='Rectangle 272' stroke='#fff' strokeWidth='1' opacity='0.502'>
        <rect width='39' height='33' rx='4' stroke='none'/>
        <rect x='0.5' y='0.5' width='38' height='32' rx='3.5' fill='none'/>
      </g>
      <g id='Rectangle_76' data-name='Rectangle 76' transform='translate(6 5)' fill='none' stroke='#fff' strokeWidth='2'>
        <rect width='12' height='10' rx='2' stroke='#fff' strokeWidth='1' />
        <rect x='1' y='1' width='10' height='8' rx='1' fill='none'/>
      </g>
      <g id='Rectangle_274' data-name='Rectangle 274' transform='translate(21 5)' fill='none' stroke='#fff' strokeWidth='2'>
        <rect width='12' height='10' rx='2' stroke='#fff' strokeWidth='1'/>
        <rect x='1' y='1' width='10' height='8' rx='1' fill='none'/>
      </g>
      <g id='Rectangle_77' data-name='Rectangle 77' transform='translate(6 18)' fill='none' stroke='#fff' strokeWidth='2'>
        <rect width='12' height='10' rx='2' stroke='#fff' strokeWidth='1'/>
        <rect x='1' y='1' width='10' height='8' rx='1' fill='none'/>
      </g>
      <g id='Rectangle_273' data-name='Rectangle 273' transform='translate(21 18)' fill='none' stroke='#fff' strokeWidth='2'>
        <rect width='12' height='10' rx='2' stroke='#fff' strokeWidth='1'/>
        <rect x='1' y='1' width='10' height='8' rx='1' fill='none'/>
      </g>
    </svg>
  }

}
