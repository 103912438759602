import { SocketContext } from '../context/Socket'
import { useContext } from 'react'


function useSocket() {
  const socket = useContext(SocketContext)

  // This 'if' makes it possible to use useSocket
  // in components without having to check for nulls.
  if (socket === null)
    throw 'Socket does not exist.'

  return socket
}

export default useSocket
