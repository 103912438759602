import * as mobx from 'mobx'
import { Socket } from 'socket.io-client'
import i18n from 'i18next'
import { toast } from 'react-toastify'


class ChatState {
  private socket: Socket
  messages: Message[] = []
  isOpen = false

  constructor(socket: Socket) {
    mobx.makeObservable(this, {
      messages: mobx.observable,
      addMessage: mobx.action,
      unreadMessages: mobx.computed,
      isOpen: mobx.observable,
      open: mobx.action.bound,
      close: mobx.action.bound,
    })

    this.socket = socket
    this.socket.on('chat message', (userId: string, sender: string, content: string) => {
      this.addMessage({ sender, content, time: new Date() })
      if (!this.isOpen)
        toast(i18n.t('notifications.newMessage', { name: sender, message: content }))
    })
  }

  addMessage(message: Message) {
    if (this.isOpen)
      message.isRead = true

    this.messages = [...this.messages, message]

    if (message.ownMessage)
      this.socket.emit('chat message', message.sender, message.content)
  }

  get unreadMessages() {
    return this.messages.filter(message => !message.isRead).length
  }

  open() {
    // Mark the messages read when opening chat.
    this.messages = this.messages.map(message => ({...message, isRead: true}))
    this.isOpen = true
  }

  close() {
    this.isOpen = false
  }
}

export default ChatState
