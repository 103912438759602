import * as mobx from 'mobx'
import React from 'react'
import { handleMediaDevicesError } from '../utils/devices'
import i18n from 'i18next'
import { toast } from 'react-toastify'


export class LocalSettings {
  name: string | undefined
  devices: MediaDeviceInfo[] = []
  camera: string | undefined = undefined
  cameraEnabled = true
  microphone: string | undefined = undefined
  microphoneEnabled = true
  cameraStream: MediaStream
  screenShareStream: MediaStream | null = null
  error: string | null = null
  output: string | undefined = undefined

  constructor() {
    this.name = localStorage.getItem('name') || undefined
    this.cameraStream = new MediaStream()

    mobx.makeObservable(this, {
      name: mobx.observable,
      setName: mobx.action.bound,

      devices: mobx.observable,
      setDevices: mobx.action.bound,
      updateDevices: mobx.action.bound,
      videoDevices: mobx.computed,
      audioDevices: mobx.computed,
      outputDevices: mobx.computed,

      camera: mobx.observable,
      setCamera: mobx.action.bound,
      cameraEnabled: mobx.observable,
      toggleCamera: mobx.action.bound,

      microphone: mobx.observable,
      setMicrophone: mobx.action.bound,
      microphoneEnabled: mobx.observable,
      toggleMicrophone: mobx.action.bound,

      output: mobx.observable,
      setOutput: mobx.action.bound,

      cameraStream: mobx.observable,
      setCameraStream: mobx.action.bound,
      refreshCameraStream: mobx.action.bound,
      screenShareStream: mobx.observable,
      stopStreams: mobx.action.bound,
      stopScreenShareStream: mobx.action.bound,
      setScreenShareStream: mobx.action.bound,

      error: mobx.observable,
      setError: mobx.action.bound,
    })

    mobx.reaction(
      () => ({ cameraStream: this.cameraStream, cameraEnabled: this.cameraEnabled }),
      () => this.cameraStream.getVideoTracks().forEach(track => track.enabled = this.cameraEnabled)
    )
    mobx.reaction(
      () => ({ cameraStream: this.cameraStream, microphoneEnabled: this.microphoneEnabled }),
      () => this.cameraStream.getAudioTracks().forEach(track => track.enabled = this.microphoneEnabled)
    )
  }

  setName(name: string) {
    this.name = name
    localStorage.setItem('name', name)
  }

  setDevices(devices: MediaDeviceInfo[]) {
    // Some operating systems return duplicates. Remove them with the below code.
    this.devices = devices.reduce((previousDevices: MediaDeviceInfo[], currentDevice) => {
      if (!previousDevices.find(device => device.kind == currentDevice.kind && device.deviceId == currentDevice.deviceId))
        previousDevices.push(currentDevice)

      return previousDevices
    }, [])

    return devices
  }

  updateDevices() {
    return navigator.mediaDevices.enumerateDevices().then(this.setDevices)
  }

  get videoDevices() {
    return this.devices.filter(device => device.kind == 'videoinput')
  }

  get audioDevices() {
    return this.devices.filter(device => device.kind == 'audioinput')
  }

  get outputDevices() {
    return this.devices.filter(device => device.kind == 'audiooutput')
  }

  setCamera(deviceId: string | undefined) {
    this.camera = deviceId
  }

  setMicrophone(deviceId: string | undefined) {
    this.microphone = deviceId
  }

  setOutput(deviceId: string | undefined) {
    this.output = deviceId
  }

  toggleCamera() {
    this.cameraEnabled = !this.cameraEnabled
  }

  toggleMicrophone() {
    this.microphoneEnabled = !this.microphoneEnabled
  }

  setError(error: Error) {
    this.error = error.message
    handleMediaDevicesError(error)
  }

  refreshCameraStream() {
    this.error = null
    const constraints: MediaStreamConstraints = {}

    // If permissions to mediaDevices is not given, enumerateDevices will not return deviceIds.
    constraints.video = this.camera
      ? { deviceId: { exact: this.camera } }
      : !!this.devices.filter(d => d.kind === 'videoinput').length

    // If an exact deviceId is given, use that
    // If we don't know exact deviceId, return empty object for later step
    // If we don't detect any microphone devices, return false to prevent errors 
    constraints.audio = this.microphone
      ? { deviceId: { exact: this.microphone } }
      : this.devices.filter(d => d.kind === 'audioinput').length ? {} : false

    // If microphone devices was detected, apply following constraints to microphone
    if (constraints.audio) {
      constraints.audio.autoGainControl = true
      constraints.audio.noiseSuppression = true
      constraints.audio.echoCancellation = true
    }

    if (constraints.video || constraints.audio) {
      this.cameraStream.getTracks().forEach(track => track.stop())
      navigator.mediaDevices.getUserMedia(constraints).then(this.setCameraStream).catch(this.setError)
    } else {
      this.setCameraStream(new MediaStream())
    }
  }

  setCameraStream(stream: MediaStream) {
    this.cameraStream = stream
  }

  setScreenShareStream(stream: MediaStream) {
    this.screenShareStream = stream
    this.screenShareStream.getTracks().forEach(t => t.onended = this.stopScreenShareStream)
    toast(i18n.t('notifications.localScreenshareStarted'))
  }

  stopScreenShareStream() {
    if (this.screenShareStream) toast(i18n.t('notifications.localScreenshareEnded'))
    this.screenShareStream?.getTracks().forEach(track => track.stop())
    this.screenShareStream = null
  }

  // "Destroys" the streams. Call this method when the call ends.
  stopStreams() {
    this.cameraStream.getTracks().forEach(track => track.stop())
    this.cameraStream = new MediaStream()
    this.stopScreenShareStream()
  }
}

export const LocalSettingsContext = React.createContext<LocalSettings | undefined>(undefined)

export function useLocalSettings() {
  const context = React.useContext(LocalSettingsContext)
  if (!context) throw 'Context provider missing.'
  return context
}
