import type { LocalSettings } from '../store/LocalSettings'

export function isAudioOutputSupported() {
  // eslint-disable-next-line
  const tempElem = document.createElement('video') as any
  const supported = typeof tempElem.sinkId !== 'undefined'
  tempElem.remove()
  return supported
}

export function handleMediaDevicesError(error: Error) {
  const errorsToIgnore = [
    'NotAllowedError',
    'NotFoundError',
    'NotReadableError',
    'SecurityError',
  ]
  if (errorsToIgnore.includes(error.name)) return
  throw error
}

export function getDevicesForLogging({
  cameraStream,
  outputDevices,
  output,
}: LocalSettings) {
  const cameraDevice = cameraStream.getVideoTracks().length
    ? cameraStream.getVideoTracks()[0].label
    : undefined

  const microphoneDevice = cameraStream.getAudioTracks().length
    ? cameraStream.getAudioTracks()[0].label
    : undefined

  let speakerDevice = outputDevices.find(d => d.deviceId === output)?.label
  if (!speakerDevice)
    speakerDevice = outputDevices.find(d => d.deviceId === 'default')?.label

  return {
    userAgent: navigator.userAgent,
    cameraDevice,
    microphoneDevice,
    speakerDevice
  }
}

