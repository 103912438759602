import React, { CSSProperties } from 'react'


export function ChatCloseIcon() {
  return <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>
    <g id='chat_close' transform='translate(-1167 -12)'>
      <g id='Ellipse_29' data-name='Ellipse 29' transform='translate(1167 12)' fill='#fff' stroke='#e9e9e9' strokeWidth='1'>
        <circle cx='15' cy='15' r='15' stroke='none'/>
        <circle cx='15' cy='15' r='14.5' fill='none'/>
      </g>
      <g id='Group_54' data-name='Group 54' transform='translate(1172.625 17.631)'>
        <path id='Path_84' data-name='Path 84' d='M4.375,14.369l10-10' style={ style } />
        <path id='Path_85' data-name='Path 85' d='M14.375,14.369l-10-10' style={ style } />
      </g>
    </g>
  </svg>
}

const style: CSSProperties = {
  fill:           'none',
  stroke:         '#333',
  strokeLinecap:  'round',
  strokeLinejoin: 'round',
  strokeWidth:    2,
}
