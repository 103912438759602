import * as mobxReact from 'mobx-react-lite'
import { ChatCloseIcon, SendMessageIcon } from '../../components/icons'
import ChatMessage from './ChatMessage'
import ChatState from '../../store/Chat'
import React from 'react'
import { useLocalSettings } from '../../store/LocalSettings'
import { useTranslation } from 'react-i18next'
import { useWebRTCState } from '../../store/WebRTCState'


type Props = {
  chat: ChatState
}

function ChatView({ chat }: Props) {
  const { t } = useTranslation()
  const { name } = useLocalSettings()
  const [message, setMessage] = React.useState('')
  const { hasEmployeeInCall, remoteUsers } = useWebRTCState()
  const [errorVisible, setErrorVisible] = React.useState(false)

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (!hasEmployeeInCall || !remoteUsers.length)
      return setErrorVisible(true)

    chat.addMessage({
      sender: name || '',
      content: message,
      time: new Date(),
      isRead: true,
      ownMessage: true,
    })
    setMessage('')
  }

  React.useEffect(() => {
    if (hasEmployeeInCall && remoteUsers.length && errorVisible) setErrorVisible(false)
  }, [hasEmployeeInCall, remoteUsers])

  return (
    <div id="chat-view" className={chat.isOpen ? 'open' : ''}>
      <header>
        <a onClick={chat.close} role="button">
          <ChatCloseIcon/>
        </a>
        <h2>{t('chat.title')}</h2>
      </header>
      <section className="messages-container">
        <div className="messages">
          {chat.messages
            .slice()
            .sort((a, b) => a.time.getTime() - b.time.getTime())
            .reverse()
            .map((message, i) => <ChatMessage key={i} {...message} />)}
        </div>
      </section>

      {errorVisible && <p className='message-error'>
        {t('connectionStatus.waitForJoin')}
      </p> }

      <form className="message-form" onSubmit={handleSubmit}>
        <input
          autoComplete="off"
          name="message"
          value={message}
          onChange={event => setMessage(event.target.value)}
          placeholder={t('chat.placeholder')}
          required
        />
        <button className="button" type="submit">
          <SendMessageIcon/>
        </button>
      </form>
    </div>
  )
}

export default mobxReact.observer(ChatView)
