import React from 'react'
import { useTranslation } from 'react-i18next'

import { BrandProvider, useBrand } from './context/Brand'
import Card from './components/Card'
import Room from './views/Room'
import spinnerImage from './assets/loading.gif'


function App() {
  const { t } = useTranslation()
  const { roomAvailable } = useBrand()

  if (roomAvailable === null)
    return <Card noLogo={true}><img src={spinnerImage} alt="Loading spinner." /></Card>

  if (roomAvailable === false)
    return <Card>{t('app.roomNotAvailable')}</Card>

  return <Room />
}

// eslint-disable-next-line
export default () => <BrandProvider><App/></BrandProvider>
