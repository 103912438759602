import React from 'react'


export function EndCallIcon() {
  return <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'>
    <g id='leave_call' transform='translate(-688 -646)'>
      <g id='Ellipse_25' data-name='Ellipse 25' transform='translate(688 646)' fill='#fff' stroke='#cecece' strokeWidth='1'>
        <circle cx='25' cy='25' r='25' stroke='none'/>
        <circle cx='25' cy='25' r='24.5' fill='none'/>
      </g>
      <g id='Group_39' data-name='Group 39' transform='translate(735.966 672.41) rotate(135)'>
        <path id='Path_24' data-name='Path 24' d='M19.077,26.686l.012.007a5.692,5.692,0,0,0,7.082-.777l.8-.8a1.9,1.9,0,0,0,0-2.684l-3.355-3.353a1.9,1.9,0,0,0-2.684,0h0a1.9,1.9,0,0,1-2.683,0l-5.368-5.369a1.9,1.9,0,0,1,0-2.684h0a1.9,1.9,0,0,0,0-2.683L9.524,4.99a1.9,1.9,0,0,0-2.684,0l-.8.8a5.694,5.694,0,0,0-.778,7.082l.008.012a51.371,51.371,0,0,0,13.8,13.806Z' transform='translate(0 0)' fill='none' stroke='#d93025' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
      </g>
    </g>
  </svg>
}
