import * as mobxReact from 'mobx-react-lite' 
import React, { useEffect, useRef } from 'react'
import { isAudioOutputSupported } from '../../utils/devices'
import { useLocalSettings } from '../../store/LocalSettings'
import { useSelectedStream } from '../../context/SelectedStream'


interface Props extends React.HTMLProps<HTMLVideoElement> {
  stream?: MediaStream
}

export const Video = mobxReact.observer((props: Props) => {
  const {setSelectedStream} = useSelectedStream()
  const videoRef = useRef<HTMLVideoElement>(null)
  const localSettings = useLocalSettings()

  useEffect(() => {
    if (!videoRef.current || !props.stream) return
    videoRef.current.srcObject = props.stream
  }, [props.stream])

  useEffect(() => {
    if (!videoRef.current || !props.stream || !localSettings.output || !isAudioOutputSupported()) return

    // Prevent AbortError from setSinkId if stream does not have audio tracks
    if (!props.stream.getAudioTracks().length) return

    // sinkId not supported by typescript so cast to any
    // eslint-disable-next-line
    const element = videoRef.current as any
    element.setSinkId(localSettings.output)
  }, [localSettings.output, props.stream])

  if (!props.stream) return null

  return <video
    onClick={() => setSelectedStream && props.stream && setSelectedStream(props.stream)}
    className={props.className}
    ref={videoRef}
    autoPlay={props.autoPlay || true}
    playsInline={props.playsInline || true}
    controls={props.controls || false}
    {...props}
  />
})
