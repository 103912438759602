import React from 'react'
import { Trans } from 'react-i18next'
import { createPortal } from 'react-dom'

import spinner from '../assets/viivi-waiting.gif'


type Props = {
  visible: boolean
  message: string
  subMessage?: string
  displaySpinner?: boolean
  onCancel?: () => void
  onAction?: () => void
} & React.HTMLProps<HTMLDivElement>

function Overlay(props: Props) {
  if (!props.visible)
    return null

  const applicationContainer = document.querySelector('.application')

  if (!applicationContainer)
    throw new Error('Application container .application not found.')

  return createPortal(
    <div className="overlay-container" style={props.style || {}}>
      <div className="overlay">
        {props.displaySpinner && <img src={spinner} alt="Loading spinner" />}
        <p className="big-text">{props.message}</p>
        {props.subMessage && <p>{props.subMessage}</p>}
        {(props.onCancel || props.onAction) && (
          <div className="overlay-footer">
            {props.onCancel && (
              <button
                className="button button-outline"
                onClick={props.onCancel}
              >
                <Trans i18nKey='footer.buttonNo'>
                  No
                </Trans>
              </button>
            )}
            {props.onAction && (
              <button className="button button-danger" onClick={props.onAction}>
                <Trans i18nKey='footer.buttonYes'>
                  Yes
                </Trans>
              </button>
            )}
          </div>
        )}
      </div>
    </div>,
    applicationContainer
  )
}

export default Overlay
