import * as Sentry from '@sentry/react'
import * as mobx from 'mobx'
import { LocalSettings } from './LocalSettings'
import React from 'react'
import { Socket } from 'socket.io-client'
import { getViiviConfig } from '../utils/webrtc-config'
import i18n from 'i18next'
import { isEmployeeLink } from '../utils/room-info'
import { toast } from 'react-toastify'


interface IBasePeer {
  socketId: string
  name: string
  isEmployee: boolean
}

export class WebRTCState {
  private socket: Socket
  private localSettings: LocalSettings
  private pendingPeers: Map<string, IBasePeer> = new Map<string, IBasePeer>()

  peers: Map<string, WebRTCPeer> = new Map<string, WebRTCPeer>()

  constructor(socket: Socket, localSettings: LocalSettings) {
    this.socket = socket
    this.localSettings = localSettings

    mobx.makeObservable(this, {
      peers: mobx.observable,

      // Socket handler functions.
      onUserJoin: mobx.action.bound,
      onExistingUser: mobx.action.bound,
      onUserLeave: mobx.action.bound,
      onUserDisconnect: mobx.action.bound,
      onActiveCameraStream: mobx.action.bound,
      onActiveScreenShareStream: mobx.action.bound,
      onOffer: mobx.action.bound,
      onAnswer: mobx.action.bound,
      onIceCandidate: mobx.action.bound,

      // Helper functions.
      createPeerConnection: mobx.action.bound,
      updatePeerConnection: mobx.action.bound,
      disconnect: mobx.action.bound,

      // Computed functions.
      remoteStreams: mobx.computed,
      remoteUserIds: mobx.computed,
      remoteUsers: mobx.computed,
      hasScreenShareStream: mobx.computed,
      hasEmployeeInCall: mobx.computed,
    })

    this.socket.on('user join', this.onUserJoin)
    this.socket.on('existing user', this.onExistingUser)
    this.socket.on('user leave', this.onUserLeave)
    this.socket.on('user disconnect', this.onUserDisconnect)
    this.socket.on('active camera stream', this.onActiveCameraStream)
    this.socket.on('active screen share stream', this.onActiveScreenShareStream)
    this.socket.on('description-offer', this.onOffer)
    this.socket.on('description-answer', this.onAnswer)
    this.socket.on('ice-candidate', this.onIceCandidate)

    mobx.reaction(
      () => localSettings.screenShareStream,
      (screenShareStream) => {
        for (const [userId, peer] of this.peers) {
          console.info(`[${userId}]: Emitting 'active screen share stream' with ID: '${this.localSettings.screenShareStream?.id}'.`)
          this.socket.emit('active screen share stream', peer.socketId, this.localSettings.screenShareStream?.id)

          if (screenShareStream) {
            const existingTracks = peer.connection.getSenders()
              .map(sender => sender.track?.id)
              .filter(trackId => trackId)

            screenShareStream.getTracks().forEach(track => {
              if (!existingTracks.includes(track.id)) {
                peer.connection.addTrack(track, screenShareStream)
                console.info(`[${peer.userId}]: Sending screen share '${track.kind}' track '${track.id}' in stream '${screenShareStream.id}'.`)
              }
            })
          }
        }
      }
    )

    mobx.reaction(
      () => localSettings.cameraStream,
      (stream) => {
        const newTracks = stream.getTracks()
        this.remoteUsers.forEach(peer => {
          newTracks.forEach(track => {
            peer.connection.getSenders().find(sender => sender.track?.kind === track.kind)?.replaceTrack(track)
          })
        })
      }
    )

    mobx.reaction(
      () => this.remoteUsers.find(peer => !!peer.screenShareStream),
      (user, prevUser) => {
        if (user && !prevUser)
          toast(i18n.t('notifications.remoteScreenshareStarted', { name: user.name }))
        else if (!user && prevUser)
          toast(i18n.t('notifications.remoteScreenshareEnded', { name: prevUser.name }))
      }
    )
  }

  /*
   * Socket handler functions below.
   */

  async onUserJoin(userId: string, socketId: string, name: string, isEmployee: boolean) {
    console.info(`[${userId}]: User joined.`)

    this.pendingPeers.set(userId, {socketId, name, isEmployee})

    if (isEmployee || this.hasEmployeeInCall) {
      for (const [pendingUserId, pendingPeer] of this.pendingPeers.entries()) {
        const peer = this.peers.get(pendingUserId)

        if (peer) {
          console.info(`[${userId}]: User already exists. Updating user.`)
          this.updatePeerConnection(peer, pendingPeer.socketId, pendingPeer.name, pendingPeer.isEmployee)
        } else {
          console.info(`[${userId}]: User does not exist. Creating user.`)
          this.createPeerConnection(pendingUserId, pendingPeer.socketId, pendingPeer.name, pendingPeer.isEmployee)
          toast(i18n.t('notifications.join', { name: pendingPeer.name }))
        }

        console.info(`[${pendingUserId}]: Emitting 'existing user'.`)
        this.socket.emit('existing user', pendingPeer.socketId)

        console.info(`[${userId}]: Emitting active streams.`)
        this.socket.emit('active camera stream', socketId, this.localSettings.cameraStream.id)
        this.socket.emit('active screen share stream', socketId, this.localSettings.screenShareStream?.id)
      }
      this.pendingPeers.clear()
    }
  }

  async onExistingUser(userId: string, socketId: string, name: string, isEmployee: boolean) {
    console.info(`[${userId}]: Received 'existing user' signal.`)

    let peer = this.peers.get(userId)

    if (peer) {
      console.info(`[${userId}]: User already exists. Updating user.`)
      this.updatePeerConnection(peer, socketId, name, isEmployee)
    } else {
      console.info(`[${userId}]: User does not exist. Creating user.`)
      peer = this.createPeerConnection(userId, socketId, name, isEmployee)
    }

    console.info(`[${userId}]: Sending tracks.`)
    peer.sendTracks()

    console.info(`[${userId}]: Emitting active streams.`)
    this.socket.emit('active camera stream', socketId, this.localSettings.cameraStream.id)
    this.socket.emit('active screen share stream', socketId, this.localSettings.screenShareStream?.id)
  }

  onUserLeave(userId: string) {
    console.info(`[${userId}]: User left.`)
    const peer = this.peers.get(userId)

    if (peer) {
      toast(i18n.t('notifications.leave', { name: peer.name }))
      peer.connection.close()
    }

    this.peers.delete(userId)
    this.pendingPeers.delete(userId)
  }

  onUserDisconnect(userId: string) {
    console.info(`[${userId}]: User disconnected.`)
  }

  onActiveCameraStream(userId: string, streamId: string) {
    const peer = this.peers.get(userId)
    if (!peer)
      return console.error(`[${userId}]: Received active camera stream ID '${streamId}', but socket was not in peers.`)

    console.info(`[${userId}]: Received active camera stream ID '${streamId}'.`)
    peer.cameraStream = streamId
  }

  onActiveScreenShareStream(userId: string, streamId: string) {
    const peer = this.peers.get(userId)
    if (!peer)
      return console.error(`[${userId}]: Received active screen share stream ID '${streamId}', but socket was not in peers.`)

    console.info(`[${userId}]: Received active screen share stream ID '${streamId}'.`)
    peer.screenShareStream = streamId
  }

  async onOffer(userId: string, description: RTCSessionDescription) {
    if (description.type != 'offer')
      throw new Error(`[${userId}]: Description type '${description.type}' is not 'offer'`)

    const peer = this.peers.get(userId)
    if (!peer)
      return console.error(`[${userId}]: Received description 'offer', but socket was not in peers.`)

    console.info(`[${userId}]: Received description 'offer'. Peer signaling state is: '${peer.connection.signalingState}'.`)

    await peer.connection.setRemoteDescription(description)

    console.info(`[${userId}]: Sending tracks.`)
    peer.sendTracks()

    console.info(`[${userId}]: Answering to offer.`)
    const answer = await peer.connection.createAnswer()
    await peer.connection.setLocalDescription(answer)
    this.socket.emit('description-answer', peer.socketId, answer)

    await peer.addPendingIceCandidates()
  }

  async onAnswer(userId: string, description: RTCSessionDescription) {
    if (description.type != 'answer')
      throw new Error(`[${userId}]: Description type '${description.type}' is not 'offer'`)

    const peer = this.peers.get(userId)
    if (!peer)
      return console.error(`[${userId}]: Received description 'answer', but socket was not in peers.`)

    console.info(`[${userId}]: Received description 'answer'. Peer signaling state is: '${peer.connection.signalingState}'.`)

    await peer.connection.setRemoteDescription(description)
    await peer.addPendingIceCandidates()
  }

  async onIceCandidate(userId: string, candidate: RTCIceCandidate) {
    const peer = this.peers.get(userId)
    if (!peer)
      return console.error(`[${userId}]: Received ICE candidate, but socket was not in peers.`)

    if (!peer.connection.remoteDescription) {
      console.debug(`[${userId}]: Received ICE candidate before remote description. Adding candidate to pending list.`)
      peer.pendingIceCandidates.push(candidate)
    } else {
      console.debug(`[${userId}]: Received ICE candidate. Adding candidate.`)
      await peer.connection.addIceCandidate(candidate)
    }
  }

  /*
   * Helper functions below.
   */

  createPeerConnection(userId: string, socketId: string, name: string, isEmployee: boolean) {
    console.info(`[${userId}]: Creating peer connection with socket ID '${socketId}'.`)

    const peer = new WebRTCPeer(
      this.socket, this.localSettings,
      userId, socketId, name, isEmployee,
      () => this.peers.delete(userId),
    )
    this.peers.set(userId, peer)
    return peer
  }

  updatePeerConnection(peer: WebRTCPeer, socketId: string, name: string, isEmployee: boolean) {
    peer.socketId = socketId
    peer.name = name
    peer.isEmployee = isEmployee
  }

  disconnect() {
    for (const [userId, peer] of this.peers.entries()) {
      peer.connection.close()
      this.peers.delete(userId)
    }
  }

  /*
   * Computed functions below.
   */

  get remoteStreams() {
    const streams: MediaStream[] = []

    for (const peer of this.remoteUsers) {
      const cameraStream = peer.streams.find(s => s.id === peer.cameraStream)
      const screenStream = peer.streams.find(s => s.id === peer.screenShareStream)
      cameraStream && streams.push(cameraStream)
      screenStream && streams.push(screenStream)
    }

    return streams
  }

  get remoteUserIds() {
    return Array.from(this.peers.keys())
  }

  get remoteUsers() {
    return Array.from(this.peers.values())
  }

  get hasScreenShareStream() {
    return Boolean(this.remoteUsers.filter((peer) => !!peer.screenShareStream).length)
  }

  get hasEmployeeInCall() {
    return this.remoteUsers.find(p => p.isEmployee) || isEmployeeLink()
  }
}


class WebRTCPeer {
  private socket: Socket
  private localSettings: LocalSettings

  userId: string
  socketId: string
  name: string
  isEmployee: boolean
  remove: () => void
  streams: MediaStream[] = []
  cameraStream?: string = undefined
  screenShareStream?: string = undefined
  connectionState?: string = undefined
  pendingIceCandidates: RTCIceCandidate[] = []
  connection: RTCPeerConnection

  constructor(
    socket: Socket,
    localSettings: LocalSettings,
    userId: string,
    socketId: string,
    name: string,
    isEmployee: boolean,
    remove: () => void,
  ) {
    this.socket = socket
    this.localSettings = localSettings

    this.userId = userId
    this.socketId = socketId
    this.name = name
    this.isEmployee = isEmployee
    this.remove = remove

    this.connection = new RTCPeerConnection(getViiviConfig())

    mobx.makeObservable(this, {
      streams: mobx.observable,
      cameraStream: mobx.observable,
      screenShareStream: mobx.observable,
      connectionState: mobx.observable,
      pendingIceCandidates: mobx.observable,
      connection: mobx.observable,

      // RTCPeerConnection handler functions.
      onTrack: mobx.action.bound,
      onNegotiationNeeded: mobx.action.bound,
      onIceCandidate: mobx.action.bound,
      onConnectionStateChange: mobx.action.bound,
      onIceConnectionStateChange: mobx.action.bound,

      // Helper functions.
      sendTracks: mobx.action.bound,
      addPendingIceCandidates: mobx.action.bound,
    })

    this.connection.ontrack = this.onTrack
    this.connection.onnegotiationneeded = this.onNegotiationNeeded
    this.connection.onicecandidate = this.onIceCandidate
    this.connection.onconnectionstatechange = this.onConnectionStateChange
    this.connection.oniceconnectionstatechange = this.onIceConnectionStateChange
  }

  /*
   * RTCPeerConnection handler functions below.
   */

  onTrack({ track, streams }: RTCTrackEvent) {
    console.info(`[${this.userId}]: Received remote '${track.kind}' track '${track.id}' in stream '${streams[0].id}'.`)

    if (!this.streams.find(s => s.id === streams[0].id))
      this.streams.push(streams[0])
  }

  async onNegotiationNeeded() {
    console.info(`[${this.userId}]: Negotiation needed. Sending offer.`)

    try {
      const offer = await this.connection.createOffer()
      await this.connection.setLocalDescription(offer)
      this.socket.emit('description-offer', this.socketId, offer)
    } catch (error) {
      console.error(`[${this.userId}]: Error while making offer.`, error)
      Sentry.captureException(error)
    }
  }

  onIceCandidate({ candidate }: RTCPeerConnectionIceEvent) {
    if (candidate)
      this.socket.emit('ice-candidate', this.socketId, candidate)
  }

  onConnectionStateChange() {
    console.info(`[${this.userId}]: Connection state changed to ${this.connection.connectionState}.`)

    this.connectionState = this.connection.connectionState

    switch(this.connection.connectionState) {
    case 'closed':
      console.info(`[${this.userId}]: Connection closed.`)
      break
    case 'disconnected':
      toast(i18n.t('notifications.disconnected', { name: this.name }))
      console.info(`[${this.userId}]: Peer disconnected.`)
      break
    case 'failed':
      toast(i18n.t('notifications.failed', { name: this.name }))
      console.warn(`[${this.userId}]: Connection failed. Removing user.`)
      this.remove()
      break
    }
  }

  onIceConnectionStateChange() {
    console.info(`[${this.userId}]: ICE connection state changed to ${this.connection.iceConnectionState}.`)
  }

  /*
   * Helper functions below.
   */

  sendTracks() {
    const existingTracks = this.connection.getSenders()
      .map(sender => sender.track?.id)
      .filter(trackId => trackId)

    this.localSettings.cameraStream.getTracks().forEach(track => {
      if (!existingTracks.includes(track.id)) {
        console.info(`[${this.userId}]: Sending camera '${track.kind}' track '${track.id}' in stream '${this.localSettings.cameraStream.id}'.`)
        this.connection.addTrack(track, this.localSettings.cameraStream)
      }
    })

    this.localSettings.screenShareStream?.getTracks().forEach(track => {
      if (this.localSettings.screenShareStream) {
        if (!existingTracks.includes(track.id)) {
          this.connection.addTrack(track, this.localSettings.screenShareStream)
          console.info(`[${this.userId}]: Sending screen share '${track.kind}' track '${track.id}' in stream '${this.localSettings.screenShareStream.id}'.`)
        }
      }
    })
  }

  async addPendingIceCandidates() {
    while (this.pendingIceCandidates.length > 0) {
      const candidate = this.pendingIceCandidates.shift()

      if (candidate) {
        console.debug(`[${this.userId}]: Adding pending ICE candidate.`)
        await this.connection.addIceCandidate(candidate)
      }
    }
  }
}

export const WebRTCStateContext = React.createContext<WebRTCState | undefined>(undefined)

export function useWebRTCState() {
  const context = React.useContext(WebRTCStateContext)
  if (!context)
    throw 'WebRTCStateContext provider missing.'
  return context
}
