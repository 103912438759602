import React from 'react'
import { useTranslation } from 'react-i18next'

import Overlay from '../../components/Overlay'


type Props = {
  visible: boolean
  onLeave: () => void
  onCancel: () => void
}

function EndCallDialog(props: Props) {
  const { t } = useTranslation()
  return <Overlay
    visible={props.visible}
    message={t('footer.confirmEndCall')}
    onAction={props.onLeave}
    onCancel={props.onCancel}
    style={{zIndex: 9999}}
  />
}

export default EndCallDialog
