import * as mobxReact from 'mobx-react-lite'
import React, { useState } from 'react'

import { ChangeLayoutIcon } from '../../components/icons'
import DefaultLayout from './DefaultLayout'
import GridLayout from './GridLayout'
import ScreenShareLayout from './ScreenShareLayout'
import { SelectedStreamProvider } from '../../context/SelectedStream'
import { useLocalSettings } from '../../store/LocalSettings'
import { useWebRTCState } from '../../store/WebRTCState'


function VideoLayout() {
  const localSettings = useLocalSettings()
  const webrtc = useWebRTCState()
  const [tiled, setTiled] = useState<boolean>(false)

  function streamsCount() {
    let count = webrtc.remoteStreams.length
    if (localSettings.cameraStream) count++
    if (localSettings.screenShareStream) count++
    return count
  }

  const hasScreenShare = (!!localSettings.screenShareStream || webrtc.hasScreenShareStream)

  function renderLayout() {
    if (hasScreenShare) return <SelectedStreamProvider><ScreenShareLayout /></SelectedStreamProvider>
    if (tiled || streamsCount() > 2) return <GridLayout />
    return <DefaultLayout />
  }

  const layoutIconVisible = !hasScreenShare && streamsCount() < 3

  return <div className="videos" data-streams-count={streamsCount()}>
    {layoutIconVisible && <nav className="floating-menu">
      <button onClick={() => setTiled(!tiled)}>
        <ChangeLayoutIcon tiled={tiled} />
      </button>
    </nav>}
    {renderLayout()}
  </div>
}

export default mobxReact.observer(VideoLayout)
