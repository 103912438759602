import React from 'react'


export function CameraSwitchIcon() {
  return <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'>
    <g id='camera_switch' transform='translate(-171 -196)'>
      <g id='Ellipse_22' data-name='Ellipse 22' transform='translate(171 196)' fill='#fff' stroke='#cecece' strokeWidth='1'>
        <circle cx='25' cy='25' r='25' stroke='none'/>
        <circle cx='25' cy='25' r='24.5' fill='none'/>
      </g>
      <g id='Group_82' data-name='Group 82'>
        <g id='Ellipse_34' data-name='Ellipse 34' transform='translate(191 216)' fill='#fff' stroke='#333' strokeWidth='2'>
          <circle cx='5' cy='5' r='5' stroke='none'/>
          <circle cx='5' cy='5' r='4' fill='none'/>
        </g>
        <g id='Group_81' data-name='Group 81'>
          <g id='Group_80' data-name='Group 80' transform='translate(182 212.497)'>
            <line id='Line_16' data-name='Line 16' x2='17.292' transform='translate(20.55 17.524) rotate(180)' fill='none' stroke='#333' strokeLinecap='round' strokeWidth='2'/>
            <line id='Line_17' data-name='Line 17' y2='17.292' transform='translate(3.258 17.524) rotate(180)' fill='none' stroke='#333' strokeLinecap='round' strokeWidth='2'/>
            <g id='Group_78' data-name='Group 78' transform='translate(0)'>
              <line id='Line_18' data-name='Line 18' y1='4.323' transform='translate(3.057 0) rotate(45)' fill='none' stroke='#333' strokeLinecap='round' strokeWidth='2'/>
              <line id='Line_19' data-name='Line 19' y2='4.323' transform='translate(6.515 3.057) rotate(135)' fill='none' stroke='#333' strokeLinecap='round' strokeWidth='2'/>
            </g>
          </g>
          <g id='Group_79' data-name='Group 79' transform='translate(189.581 211)'>
            <line id='Line_14' data-name='Line 14' x2='17.292' fill='none' stroke='#333' strokeLinecap='round' strokeWidth='2'/>
            <line id='Line_15' data-name='Line 15' y2='17.292' transform='translate(17.292)' fill='none' stroke='#333' strokeLinecap='round' strokeWidth='2'/>
            <g id='Group_77' data-name='Group 77' transform='translate(20.435 14.15) rotate(90)'>
              <line id='Line_21' data-name='Line 21' y1='4.323' transform='translate(3.057 3.057) rotate(135)' fill='none' stroke='#333' strokeLinecap='round' strokeWidth='2'/>
              <line id='Line_20' data-name='Line 20' y2='4.323' transform='translate(0 6.284) rotate(-135)' fill='none' stroke='#333' strokeLinecap='round' strokeWidth='2'/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
}
