import * as Sentry from '@sentry/react'
import axios from 'axios'


export enum ConfigType {
  VIIVI = 'viivi',
  ASPIT_MEET = 'aspit',
  TWILIO = 'twilio',
}

async function getTwilioConfig() {
  try {
    console.info('Using Twilio WebRTC config.')
    const token = await axios.get('/api/nts-token')
    return token.data
  } catch(error) {
    console.error('Error while getting Twilio WebRTC credentials.', error)
    Sentry.captureException(error)
  }
}

async function getAspitMeetConfig() {
  try {
    console.info('Using Aspit Meet WebRTC config.')
    const credentials = (await axios.get('/api/credentials')).data
    return {
      iceServers: [
        {
          urls: 'stun:turn.fns.fi:5349'
        },
        {
          urls: [
            'turn:turn-stage.aspit.no:3478?transport=udp',
            'turn:turn-stage.aspit.no:3478?transport=tcp',
            'turns:turn-stage.aspit.no:443?transport=tcp',
          ],
          username: credentials.username,
          credential: credentials.password
        }
      ]
    }
  } catch(error) {
    console.error('Error while getting WebRTC credentials.', error)
    Sentry.captureException(error)
  }
}

export function getViiviConfig() {
  console.info('Using Viivi WebRTC config.')

  return {
    iceServers: [
      // Old TURN 3478/5349 ports:
      {
        urls: ['stun:turn.fns.fi:5349', 'stun:turn.fns.fi:3478'],
      },
      {
        urls: ['turns:turn.fns.fi:5349', 'turn:turn.fns.fi:5349', 'turn:turn.fns.fi:3478'],
        username: 'turnmanager',
        credential: 'gZELCY7JejYYWa0dVgux',
      },

      // New TURN 80/443 ports:
      {
        urls: ['stun:turn.fns.fi:443', 'stun:turn.fns.fi:80'],
      },
      {
        urls: ['turns:turn.fns.fi:443', 'turn:turn.fns.fi:443', 'turn:turn.fns.fi:80'],
        username: 'turnmanager',
        credential: 'gZELCY7JejYYWa0dVgux',
      },
    ]
  }
}

/**
 * Returns config for RTCPeerConnection depending on `webrtc-config` url query parameter.
 * If the query parameter is not defined, config will default to Viivi WebRTC config.
 *
 * Valid parameters are "viivi" and "aspit"
 *
 * @returns Config for RTCPeerConnection
 */
export async function getConfig() {
  const type = new URLSearchParams(window.location.search).get('webrtc-config') || ConfigType.VIIVI

  if (type === ConfigType.VIIVI) return getViiviConfig()
  else if (type === ConfigType.ASPIT_MEET) return await getAspitMeetConfig()
  else if (type === ConfigType.TWILIO) return await getTwilioConfig()

  console.error(`No WebRTC config defined for type ${type}.`)
}
