import React from 'react'
import { useTranslation } from 'react-i18next'


function ChatMessage(props: Message) {
  const { t } = useTranslation()

  function getTimestamp() {
    let h: string | number = props.time.getHours()
    let m: string | number = props.time.getMinutes()
    while (h.toString().length < 2) h = '0' + h.toString()
    while (m.toString().length < 2) m = '0' + m.toString()
    return [h, m].join(':')
  }

  function linkifyContent() {
    // Regex taken from viivi 2
    // eslint-disable-next-line
    const regex = /((?:https?\:\/\/(?:www\.)?)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*))/gi

    return props.content
      .trim()
      .split(regex)
      .map((part, i) => regex.test(part)
        ? <a key={i} href={part.match(/https?/) ? part : `http://${part}`} target="_blank" rel="noreferrer">{part}</a>
        : part)
  }

  return <div className={`message ${props.ownMessage ? 'sent' : 'received'}`}>
    <span className='user'>{props.ownMessage ? t('chat.you') : props.sender}</span>
    <time className='timestamp'>{getTimestamp()}</time>
    <main className='content'>
      {linkifyContent()}
    </main>
  </div>
}

export default ChatMessage
