import * as mobxReact from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import { WebRTCState, WebRTCStateContext } from '../../store/WebRTCState'
import Card from '../../components/Card'
import ChatState from '../../store/Chat'
import ChatView from '../Chat'
import Footer from './Footer'
import StatusOverlay from './StatusOverlay'
import VideoLayout from '../VideoLayout'
import { useLocalSettings } from '../../store/LocalSettings'
import useSocket from '../../hooks/useSocket'
import { useTranslation } from 'react-i18next'
import { getDevicesForLogging } from '../../utils/devices'


type Props = {
  endCall: (error?: Error) => void
}

export enum Status {
  WAITING,
  CONNECTED,
}

function VideoCallView(props: Props) {
  const socket = useSocket()
  const localSettings = useLocalSettings()
  const [chat] = useState(() => new ChatState(socket))
  const [webrtc] = useState(() => new WebRTCState(socket, localSettings))
  const [status, setStatus] = useState<Status>(Status.WAITING)
  const [socketInitialized, setSocketInitialized] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    socket.on('init complete', () => {
      console.info('Socket initialized. Joining call.')
      setSocketInitialized(true)
      socket.emit('join call')
      socket.emit('log devices', getDevicesForLogging(localSettings))
    })
    socket.connect()
  }, [])

  useEffect(() => {
    socket.connected && socket.emit('log devices', getDevicesForLogging(localSettings))
  }, [localSettings.cameraStream, localSettings.output])

  useEffect(() => () => {
    webrtc.disconnect()
    localSettings.stopStreams()
  }, [])

  useEffect(() => {
    setStatus(webrtc.remoteUsers.length > 0 ? Status.CONNECTED : Status.WAITING)
  }, [webrtc.remoteUsers])

  if (!socketInitialized)
    return <Card>{t('app.connecting')}</Card>

  return <WebRTCStateContext.Provider value={webrtc}>
    <div id="video-call">
      <StatusOverlay status={status} />

      <VideoLayout />

      <ChatView chat={chat} />

      <Footer
        unreadMessages={chat.unreadMessages}
        onEndCall={() => props.endCall()}
        openChat={chat.open}
      />
    </div>
  </WebRTCStateContext.Provider>
}

export default mobxReact.observer(VideoCallView)
